import React from 'react'
import { Route, Routes } from 'react-router-dom'
import DepositLog from '../myLogs/DepositLog'
import WithdrawLogs from '../myLogs/WithdrawLogs'
import TransactionLogs from '../myLogs/TransactionLogs'

function MyLogsRoute() {
  return (
    <>
      <Routes>
        <Route path='/my-logs/deposit-log'  element={<DepositLog/>}/>
        <Route path='/my-logs/withdraw-log' element={<WithdrawLogs/>}/>
        <Route path='/my-logs/transaction-log' element={<TransactionLogs/>}/>
      </Routes>
    </>
  )
}

export default MyLogsRoute
