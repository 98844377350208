import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AllBlogs from '../blogsDetails/AllBlogs'
import BlogDetail from '../blogsDetails/BlogDetail'

function BlogsRoute() {
  return (
    <>
      <Routes>
        <Route path='/all-blog' element={<AllBlogs/>}/>
        <Route path='/blog-detail' element={<BlogDetail/>}/>
      </Routes>
    </>
  )
}

export default BlogsRoute
