import React from 'react'
import image from '../../assets/Logo/Rectangle 84.png'

function AboutUs() {
  return (
    <>
      <div className="bg-dark-blue px-3">
        <div className="d-flex align-items-center">
          <div>
            <h5 className="mb-0 py-1">About Us</h5>
          </div>
        </div>
      </div>

    <div className='d-flex flex-column flex-md-row flex-lg-column flex-xl-row justify-content-start align-items-center py-3'>  
        <div className="d-flex flex-column ms-md-2 me-3 mt-3 mt-md-0">
            <h5 className='py-3 text-center text-md-start'>
                What is Lorem Ipsum?    
            </h5>
            <p className='fw-normal text-center text-md-start'>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text 
                ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not 
                only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the
                release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus.     
            </p>   
        </div>
        <img 
        src={image}  
        alt='square'
        width={400} 
        // height={350} 
        style={{ borderRadius: '10px', maxWidth: '100%', height: 'auto' }} 
        />
    </div>
    <div className='d-flex flex-column flex-md-row flex-lg-column flex-xl-row justify-content-start align-items-center py-3'>
        <img 
        src={image}  
        alt='square'
        width={400} 
        // height={350} 
        style={{ borderRadius: '10px', maxWidth: '100%', height: 'auto' }} 
        />
        <div className="d-flex flex-column ms-md-4 mt-3 mt-md-0">
            <h5 className='py-3 text-center text-md-start'>
                Where does it come from?
            </h5>
            <p className='fw-normal text-center text-md-start'>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text 
                ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not 
                only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the
                release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus.     
            </p>   
        </div>
    </div>
    </>
  )
}

export default AboutUs
