import React, { useState } from 'react';
import { IoIosArrowBack, IoIosArrowRoundForward } from 'react-icons/io';
import ellipse from '../../assets/Logo/Ellipse 7.png';
import teamone from '../../assets/Image/Asset 1 1 (1).png';
import teamtwo from '../../assets/Image/Asset 3@3x 1 (1).png';
import { useNavigate } from 'react-router-dom';
import { eventBus } from '../eventBus';

function LiveBets() {
    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState(0);

    const handleClick = (index) => {
        setActiveIndex(index);
    };

    const list = [
        { id: 1, question: "Who will win the match?", odds: [{ team: 'India', value: '1.28' }, { team: 'Scotland', value: '5.39' }] },
        { id: 2, question: "Who will win the match?", odds: [{ team: 'India', value: '1.28' }, { team: 'Scotland', value: '5.39' }] },
        { id: 3, question: "Who won the last time they met?", odds: [{ team: 'India', value: '1.28' }, { team: 'Scotland', value: '5.39' }] },
        { id: 4, question: "Who won the last time they met?", odds: [{ team: 'India', value: '1.28' }, { team: 'Scotland', value: '5.39' }] },
        { id: 5, question: "How are the odds shaping up for this match?", odds: [{ team: 'India', value: '1.28' }, { team: 'Scotland', value: '5.39' }] },
        { id: 6, question: "How are the odds shaping up for this match?", odds: [{ team: 'India', value: '1.28' }, { team: 'Scotland', value: '5.39' }] },
        { id: 7, question: "What are the match conditions?", odds: [{ team: 'India', value: '1.28' }, { team: 'Scotland', value: '5.39' }] },
        { id: 8, question: "How did they perform in their last few games?", odds: [{ team: 'India', value: '1.28' }, { team: 'Scotland', value: '5.39' }] }
    ];

    const sendData = (data) => {
        eventBus.emit('betslipDetail', data);
    };

    return (
        <>
            <div className='bg-dark-blue px-3'>
                <div className="d-flex align-items-center ">
                    <IoIosArrowBack className='fs-2 mx-1' style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                    <div className="ms-3">
                        {/* First row: Title */}
                        <div>
                            <h5 className='mb-0'>IND vs SCO</h5>
                        </div>
                        {/* Second row: Paragraph with arrow and text */}
                        <div className="d-flex align-items-center">
                            <p className="mb-0">Home</p>
                            <IoIosArrowRoundForward className='mx-1 fs-1' />
                            <span>India vs Scotland</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-12 mt-4">
                <div className="dropdown-widget mt-3 image-bg-opacity m-0">
                    <ul>
                        <li className="list-heading border-0 carousel-radius">
                            <div className="d-flex justify-content-center align-items-center" style={{
                                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.5)'
                            }}>
                                <img src={ellipse} alt="circle" style={{ width: '22px', height: '22px' }} />
                                <h4 className="text-center fs-5 p-3 mb-0">Eredivisie league</h4>
                            </div>
                        </li>
                        <div className="live-bet-card d-flex align-items-center justify-content-center">
                            <div className="live-bet-card-content w-100">
                                <div className="d-flex align-items-center justify-content-around">
                                    <div className="team-container text-center">
                                        <div className="live-teamone-image">
                                            <img src={teamone} alt="teama" className="live-teama-image" />
                                            <img src={teamone} alt="teama" className="team-image live-teamone-opacity" />
                                        </div>
                                        <h4 className="mt-4 mb-0">IND</h4>
                                    </div>
                                    <div className="d-flex flex-column text-center">
                                        <button className="btn-timing mb-2 fs-5">7h 34m</button>
                                        <p className="text-small">Today 7:30 am</p>
                                    </div>
                                    <div className="team-container text-center">
                                        <div className="teamtwo-image">
                                            <img src={teamtwo} alt="teamb" className="live-teamb-image" />
                                            <img src={teamtwo} alt="teamb" className="team-image live-teamtwo-opacity" />
                                        </div>
                                        <h4 className="mt-4 mb-0">SCO</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ul>
                </div>
            </div>

            <div className="bg-match px-4 mt-4">
                <ul className="list-style-none p-0 flex-row mb-0 pointer">
                    <li
                        className={`py-2 ${activeIndex === 0 ? 'list-active fw-600 gradient-text' : ''}`}
                        onClick={() => handleClick(0)}
                    >
                        All
                    </li>
                    <li
                        className={`py-2 ${activeIndex === 1 ? 'list-active fw-600 gradient-text' : ''}`}
                        onClick={() => handleClick(1)}
                    >
                        Winner
                    </li>
                    <li
                        className={`py-2 ${activeIndex === 2 ? 'list-active fw-600 gradient-text' : ''}`}
                        onClick={() => handleClick(2)}
                    >
                        Goal Score
                    </li>
                    <li
                        className={`py-2 ${activeIndex === 3 ? 'list-active fw-600 gradient-text' : ''}`}
                        onClick={() => handleClick(3)}
                    >
                        Team Wins
                    </li>
                    <li
                        className={`py-2 ${activeIndex === 4 ? 'list-active fw-600 gradient-text' : ''}`}
                        onClick={() => handleClick(4)}
                    >
                        Other
                    </li>
                </ul>
            </div>

            {/* live betting option card */}
            <div className="row mt-4">
                {list.map((item, index) => (
                    <div key={index} className="col-12 col-xl-6 col-lg-12 col-md-6 mb-3">
                        <div className="d-flex align-items-center bg-dark-blue p-3">
                            <div className="w-100">
                                <p>{item.question}</p>
                                <div className="row">
                                    {item.odds.map((odd, idx) => (
                                        <div key={idx} className="col-6 mb-2">
                                            <button
                                                className="w-100 live-betting ms-0 d-flex justify-content-between"
                                                onClick={() => sendData({ itemId: item.id, odd })}
                                            >
                                                <span className="ms-3">{odd.team}</span>
                                                <span className="float-end me-3">{odd.value}</span>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {/* live betting option card end */}
        </>
    );
}

export default LiveBets;
