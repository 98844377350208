import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ReferFriend from '../referFriend/ReferFriend'

function ReferRoute() {
  return (
    <>
      <Routes>
        <Route path='/refer-a-friend' element={<ReferFriend/>}/>
      </Routes>
    </>
  )
}

export default ReferRoute
