import React, { useState, useEffect } from 'react';
import teamOne from '../../assets/Image/Asset 1 1 (1).png';
import teamTwo from '../../assets/Image/Asset 3@3x 1 (1).png';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import ReactPaginate from 'react-paginate';

const dummyData = [
  {
    id: 1,
    state: 1,
    event: "India vs Scotland",
    question: "Who will win the match?",
    threat: "India",
    predictAmount: "$235",
    payout: "$0",
    date: "7 Aug | 7:30 pm",
    result: "PENDING",
  },  
  {
    id: 2,
    state: 2,
    event: "India vs Scotland",
    question: "Who will win the match?",
    threat: "India",
    predictAmount: "$235",
    payout: "$0",
    date: "7 Aug | 7:30 pm",
    result: "WON",
  },  
  {
    id: 3,
    state: 3,
    event: "India vs Scotland",
    question: "Who will win the match?",
    threat: "India",
    predictAmount: "$235",
    payout: "$0",
    date: "7 Aug | 7:30 pm",
    result: "LOST",
  },  
  {
    id: 4,
    state: 4,
    event: "India vs Scotland",
    question: "Who will win the match?",
    threat: "India",
    predictAmount: "$235",
    payout: "$0",
    date: "7 Aug | 7:30 pm",
    result: "REFUND",
  },
  {
    id: 5,
    state: 1,
    event: "India vs Scotland",
    question: "Who will win the match?",
    threat: "India",
    predictAmount: "$235",
    payout: "$0",
    date: "7 Aug | 7:30 pm",
    result: "PENDING",
  },  
  {
    id: 6,
    state: 2,
    event: "India vs Scotland",
    question: "Who will win the match?",
    threat: "India",
    predictAmount: "$235",
    payout: "$0",
    date: "7 Aug | 7:30 pm",
    result: "WON",
  },  
  {
    id: 7,
    state: 3,
    event: "India vs Scotland",
    question: "Who will win the match?",
    threat: "India",
    predictAmount: "$235",
    payout: "$0",
    date: "7 Aug | 7:30 pm",
    result: "LOST",
  },  
  {
    id: 8,
    state: 4,
    event: "India vs Scotland",
    question: "Who will win the match?",
    threat: "India",
    predictAmount: "$235",
    payout: "$0",
    date: "7 Aug | 7:30 pm",
    result: "REFUND",
  },
  {
    id: 9,
    state: 1,
    event: "India vs Scotland",
    question: "Who will win the match?",
    threat: "India",
    predictAmount: "$235",
    payout: "$0",
    date: "7 Aug | 7:30 pm",
    result: "PENDING",
  },  
  {
    id: 10,
    state: 2,
    event: "India vs Scotland",
    question: "Who will win the match?",
    threat: "India",
    predictAmount: "$235",
    payout: "$0",
    date: "7 Aug | 7:30 pm",
    result: "WON",
  },  
  {
    id: 11,
    state: 3,
    event: "India vs Scotland",
    question: "Who will win the match?",
    threat: "India",
    predictAmount: "$235",
    payout: "$0",
    date: "7 Aug | 7:30 pm",
    result: "LOST",
  },  
  {
    id: 12,
    state: 4,
    event: "India vs Scotland",
    question: "Who will win the match?",
    threat: "India",
    predictAmount: "$235",
    payout: "$0",
    date: "7 Aug | 7:30 pm",
    result: "REFUND",
  },
  {
    id: 13,
    state: 1,
    event: "India vs Scotland",
    question: "Who will win the match?",
    threat: "India",
    predictAmount: "$235",
    payout: "$0",
    date: "7 Aug | 7:30 pm",
    result: "PENDING",
  },  
  {
    id: 14,
    state: 2,
    event: "India vs Scotland",
    question: "Who will win the match?",
    threat: "India",
    predictAmount: "$235",
    payout: "$0",
    date: "7 Aug | 7:30 pm",
    result: "WON",
  },  
  {
    id: 15,
    state: 3,
    event: "India vs Scotland",
    question: "Who will win the match?",
    threat: "India",
    predictAmount: "$235",
    payout: "$0",
    date: "7 Aug | 7:30 pm",
    result: "LOST",
  },
];

function MyBets() {
  const [page, setPage] = useState(0);
  const [itemsPerPage] = useState(5); 
  const [totalPages, setTotalPages] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);

  useEffect(() => {
    const offset = page * itemsPerPage;
    const paginatedItems = dummyData.slice(offset, offset + itemsPerPage);
    setCurrentItems(paginatedItems);
    setTotalPages(Math.ceil(dummyData.length / itemsPerPage));
  }, [page, itemsPerPage]);

  const handlePageChange = ({ selected }) => {
    setPage(selected);
  };

  return (
    <>
      <div className="bg-dark-blue px-3">
        <div className="d-flex align-items-center">
          <div>
            <h5 className="mb-0 py-1">My Bets</h5>
          </div>
        </div>
      </div>

      <div className="table-responsive mt-2" id="basic-datatable-preview">
        <table
          id="paginated-list"
          className="table nowrap w-100 text-center spacing-table"
        >
          <thead>
            <tr>
              <th style={{ borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' ,width: "100.597px"}}>Sr</th>
              <th style={{ width: "150.597px" }}>Event</th>
              <th style={{ width: "210.597px" }}>Question</th>
              <th style={{ width: "180.597px" }}>Threat</th>
              <th style={{ width: "160.597px" }} >Predict Amount</th>
              <th style={{ width: "100.597px" }}>Payout</th>
              <th style={{ width: "180.597px" }}>Date & Time</th>
              <th style={{ borderTopRightRadius: '10px', borderBottomRightRadius: '10px' , width: "150.597px"}}>Result</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item, index) => (
              <tr key={item.id}>
                <td style={{ width: "100.597px" }} className="align-middle">{index + 1 + page * itemsPerPage}</td>
                <td style={{ width: "150.597px" }} className="align-middle">
                  <div className="d-flex flex-column align-items-start justify-content-center">
                    <div className="d-flex align-items-center">
                      <img src={teamOne} alt="teamOne" width={30} height={30} className="rounded-circle me-2" />
                      <span>India</span>
                    </div>
                    <span className="text-center my-1" style={{ marginLeft: '38px' }}>vs</span>
                    <div className="d-flex align-items-center">
                      <img src={teamTwo} alt="teamTwo" width={30} height={30} className="rounded-circle me-2" />
                      <span>Scotland</span>
                    </div>
                  </div>
                </td>
                <td style={{ width: "210.597px" }} className="align-middle">{item.question}</td>
                <td style={{ width: "180.597px" }} className="align-middle">{item.threat}</td>
                <td style={{ width: "160.597px" }} className="align-middle">{item.predictAmount}</td>
                <td style={{ width: "100.597px" }} className="align-middle">{item.payout}</td>
                <td style={{ width: "180.597px" }} className="align-middle">{item.date}</td>
                <td style={{ width: "150.597px" }} className="align-middle">
                  {item.state ===1 && <span className={`badge badge-warning-lighten`}>{item.result} </span>}
                  {item.state ===2 && <span className={`badge badge-success-lighten`}>{item.result} </span>}
                  {item.state ===3 && <span className={`badge badge-danger-lighten`}>{item.result} </span>}
                  {item.state ===4 && <span className={`badge badge-info-lighten`}>{item.result} </span>}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
          </div>
        <div className="float-end mb-4">
          <ReactPaginate
            className="pagination"
            onPageChange={handlePageChange}
            pageCount={totalPages}
            previousLabel={
              <div className="d-flex align-items-center pe-2">
                <MdKeyboardArrowLeft className="fs-4" />
                <span>Back</span>
              </div>
            }
            nextLabel={
              <div className="d-flex align-items-center ps-2">
                <span>Next</span>
                <MdKeyboardArrowRight className="fs-4" />
              </div>
            }
            marginPagesDisplayed={2}
            pageRangeDisplayed={1}
            breakLabel={'..'}
            containerClassName={'pagination-container'}
            activeClassName={'pagination-active-page'}
          />
        </div>
    </>
  );
}

export default MyBets;
