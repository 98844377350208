import React from 'react'
import { Route, Routes } from 'react-router-dom'
import MyBets from '../myBets/MyBets'

function MyBetRoute() {
  return (
    <>
      <Routes>
        <Route path='/my-bets' element={<MyBets/>}/>
      </Routes>
    </>
  )
}

export default MyBetRoute
