import React, { useState } from 'react'
import refer from '../../assets/Image/image_2024_09_04T12_10_30_543Z-Photoroom 2.png'
import { ToastContainer ,toast} from 'react-toastify';

function ReferFriend() {
  const [link, setLink] = useState('https:jumbobet.refer1234/redf43');

  const copyToClipboard = () => {
    navigator.clipboard.writeText(link).then(() => {
      toast.success('Link copied to clipboard!');
    }).catch((err) => {
      console.error('Failed to copy: ', err);
    });
  };

  return (
    <>
    <ToastContainer autoClose={2000} position="top-center" />
      <div className="bg-dark-blue px-3">
        <div className="d-flex align-items-center">
          <div>
            <h5 className="mb-0 py-1">Refer a friend</h5>
          </div>
        </div>
      </div>
      <div className="bg-deposit-card mt-4">
        <p><i>EASY STEPS</i></p>
        <div className="d-flex align-items-center justify-content-center my-5 px-2">
          <img src={refer} alt="refer" width={1300}/>
        </div>
        <div className="d-flex align-items-center justify-content-between  refer-p"> 
          <p style={{marginRight:'160px'}}></p>    
          <p>Share Your <br /> Referral Code</p>
          <p>Friend Make <br /> Deposit</p>
          <p style={{marginRight:'50px'}}>Get 50,000 <br /> Promo Credits</p>
        </div>
      </div>

      <div className="my-4">
        <ul
          className="nav nav-tabs mt-2"
          id="myTab"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home"
              type="button"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              Refferal Code
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
            >
              Rewards
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="contact-tab"
              data-bs-toggle="tab"
              data-bs-target="#contact"
              type="button"
              role="tab"
              aria-controls="contact"
              aria-selected="false"
            >
              Rules
            </button>
          </li>
        </ul>
        <div className="tab-content p-3" id="myTabContent" style={{
          background:'#102460',
          borderBottomRightRadius:'10px',
          borderBottomLeftRadius:'10px'
          }}>
          <div
            className="tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <p className='mb-0'>
            Invite your friends to register
            </p>
            <div className="d-flex align-items-center justify-content-center ">
              <input 
                  type="text" 
                  value={link} 
                  onChange={(e) => setLink(e.target.value)}  
                  className='copy-input px-3'
                />
               <button
                type="button"
                style={{borderRadius:'10px'}}
                className="jumbo-btn  copy-jumbo-bg-gradient ms-3 fs-6 w-25  btn-lg  my-4  "  
              >
                Copy Link
              </button>
            </div>
          </div>

          <div
            className="tab-pane fade"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <p className='mb-0'>
              Balance Promo Bonus Credits: NAD 0.00
            </p>
            <div className="d-flex align-items-center justify-content-center ">
              <input 
                  type="text" 
                  value={link} 
                  onChange={(e) => setLink(e.target.value)}  
                  className='copy-input px-3'
                />
               <button
                type="button"
                style={{borderRadius:'10px'}}
                className="jumbo-btn  copy-jumbo-bg-gradient ms-3 fs-6 w-25  btn-lg  my-4  "  
              >
                Copy Link
              </button>
            </div>
          </div>

          <div
            className="tab-pane fade"
            id="contact"
            role="tabpanel"
            aria-labelledby="contact-tab"
          >
            <h5>Where can I get some?</h5>
            <p className=' '>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of
             type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, 
             remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more 
             recently with desktop publishing software lik.
              <br />
              <br />
              The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from
               "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 
               1914 translation by H. Rackham. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing 
               hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the 
               first true generator on the Internet. It uses a dictionary of over 200 Latin 
            </p>
            <br />
            <h5>Where can I get some?</h5>
            <p className=' '>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of
             type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, 
             remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more 
             recently with desktop publishing software lik.
              <br />
              <br />
              The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from
               "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 
               1914 translation by H. Rackham. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing 
               hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the 
               first true generator on the Internet. It uses a dictionary of over 200 Latin 
            </p>
          </div>
        </div>
      </div>

    </>
  )
}

export default ReferFriend
