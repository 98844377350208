import React, { useState } from 'react';
import map from '../../assets/Logo/maps 1.png';
import league from '../../assets/Logo/football 1.png';
import ellipse from '../../assets/Logo/Ellipse 7.png';

const DropdownWidget = () => {
  const [showAllCountries, setShowAllCountries] = useState(false);
  const [showAllLeagues, setShowAllLeagues] = useState(false);
  const [openCountryIndex, setOpenCountryIndex] = useState(null); // For countries collapse state
  const [openLeagueIndex, setOpenLeagueIndex] = useState(null); // For leagues collapse state

  const countriesList = [
    { name: 'India', tournaments: ['India'] },
    { name: 'Australia', tournaments: ['Australia'] },
    { name: 'Germany', tournaments: ['Germany'] },
    { name: 'Australia', tournaments: ['Australia'] },
    { name: 'Brazil', tournaments: ['Brazil'] },
    { name: 'Germany', tournaments: ['Germany'] },
  ];

  const leaguesList = [
    { name: 'Premier League', tournaments: ['Premier League'] },
    { name: 'La Liga', tournaments: ['La Liga'] },
    { name: 'Premier League', tournaments: ['Premier League'] },
    { name: 'Liga Apertura', tournaments: ['Liga Apertura'] },
    { name: 'Bundesliga', tournaments: ['Bundesliga'] },
    { name: 'Liga Apertura', tournaments: ['Liga Apertura'] },
  ];

  const renderList = (list, showAll, openIndex, setOpenIndex) => (
    <div className='sidebar-inner'>
      <div className='main-sitebar'>
        <ul>
          {list.slice(0, showAll ? list.length : 4).map((item, index) => (
            <li key={index}>
              <a 
                onClick={() => setOpenIndex(openIndex === index ? null : index)} // Toggle collapse
                role="button"
                aria-expanded={openIndex === index}
              >
                <img src={ellipse} alt="circle" /> {item.name}
                <i className="fa fa-angle-right" />
              </a>
              <div className={`collapse ${openIndex === index ? 'show' : ''}`}>
                <ul>
                  {item.tournaments.map((tournament, tIndex) => (
                    <li key={tIndex}>
                      <a>{tournament}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );

  return (
    <>
      {/* Countries Dropdown */}
      <div className="dropdown-widget">
        <ul>
          <li className="list-heading">
            <a href="#!">
              <img
                src={map}
                alt="img"
                className="me-1"
                style={{ width: '28px', height: '28px' }}
              />
              <h6 className="mt-1 mb-0">Countries</h6>
            </a>
          </li>
          {renderList(
            countriesList,
            showAllCountries,
            openCountryIndex,
            setOpenCountryIndex
          )}
        </ul>
        <div className="footer">
          <button
            onClick={() => setShowAllCountries(!showAllCountries)}
            className="btn-viewall text-end pe-4 text-small"
          >
            {showAllCountries ? 'Show Less' : 'View All'}
          </button>
        </div>
      </div>

      {/* Leagues Dropdown */}
      <div className="dropdown-widget">
        <ul>
          <li className="list-heading">
            <a href="#!">
              <img
                src={league}
                alt="img"
                className="me-1"
                style={{ width: '28px', height: '28px', borderRadius: '0' }}
              />
              <h6 className="mt-1 mb-0">Leagues</h6>
            </a>
          </li>
          {renderList(
            leaguesList,
            showAllLeagues,
            openLeagueIndex,
            setOpenLeagueIndex
          )}
        </ul>
        <div className="footer">
          <button
            onClick={() => setShowAllLeagues(!showAllLeagues)}
            className="btn-viewall text-end pe-4 text-small"
          >
            {showAllLeagues ? 'Show Less' : 'View All'}
          </button>
        </div>
      </div>
    </>
  );
};

export default DropdownWidget;
