import React from 'react'
import { useNavigate } from 'react-router-dom'

function Deposit() {
    const navigate = useNavigate()
  return (
    <>
      <div className="bg-dark-blue px-3">
        <div className="d-flex align-items-center">
          <div>
            <h5 className="mb-0 py-1">Deposit</h5>
          </div>
        </div>
      </div>
      
      <div className="bg-dark-blue px-3 mt-3">
      <p className="text-center my-4 mt-3"><i>Add Payment Here</i></p>
      <div className="container mb-4">
        <div className="row align-items-center">
          <div className="col-12 col-md-9 mb-3 mb-md-0">
            <input
              type='text'
              id="phone_number"
              className="form-control-2"
              style={{ background: '#102460' }}
              defaultValue="Enter Amount"
              autoComplete='off'
            />
          </div>
          <div className="col-12 col-md-3 text-center text-md-center">
            <button
              type="button"
              className="jumbo-btn jumbo-bg-gradient fs-6   btn-lg w-100"
              onClick={()=>navigate('/wallet/deposit-bank')}
            >
              Deposit Now
            </button>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Deposit
