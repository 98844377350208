import React, { useState, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import LeftSidebar from './LeftSidebar';
import RightSide from './RightSide';
import LiveBetRoute from '../routes/LiveBetRoute';
import HomeRoute from '../routes/HomeRoute';
import DashboardLeft from '../sidebarRoutes/DashboardLeft';
import { useLocation } from 'react-router-dom';
import ProfileRoute from '../routes/ProfileRoute';
import DashboardRoute from '../routes/DashboardRoute';
import MyBetRoute from '../routes/MyBetRoute';
import MyLogsRoute from '../routes/MyLogsRoute';
import WalletRoute from '../routes/WalletRoute';
import PasswordRoute from '../routes/PasswordRoute';
import ReferRoute from '../routes/ReferRoute';
import BlogsRoute from '../routes/BlogsRoute';
import FooterRoute from '../routes/FooterRoute';

function Main() {
  const location = useLocation();
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
 
  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };
 
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Header toggleSidebar={toggleSidebar} />
      <div className="main-body-area">
        <div className="row">
        {(location.pathname === '/' || location.pathname === '/live-bet' 
            || location.pathname === '/all-blog' || location.pathname === '/blog-detail' 
            || location.pathname === '/licence-info' || location.pathname === '/rules-of-bet'
            || location.pathname === '/terms' || location.pathname === '/privacy-policy'
            || location.pathname === '/about') 
            ? (
            <div className="col-xl-2 col-lg-3   d-lg-block d-none" style={{zIndex:'0'}}>
              <LeftSidebar />
            </div>
            )
            :

          (isSidebarVisible || windowWidth >= 992) && (
            <div className="col-xl-2 col-lg-3">
              <DashboardLeft  />
            </div>
            )
          }

          {(location.pathname === '/' || location.pathname === '/live-bet' 
            || location.pathname === '/all-blog' || location.pathname === '/blog-detail' 
            || location.pathname === '/licence-info' || location.pathname === '/rules-of-bet'
            || location.pathname === '/terms' || location.pathname === '/privacy-policy'
            || location.pathname === '/about') 
            ? (
            <>
              <div className="col-xl-8 col-lg-6">
                {location.pathname === '/' && <HomeRoute />}
                {location.pathname === '/live-bet' && <LiveBetRoute />}
                {location.pathname === '/all-blog' && <BlogsRoute />}
                {location.pathname === '/blog-detail' && <BlogsRoute />}
                {location.pathname === '/licence-info' && <FooterRoute />}
                {location.pathname === '/rules-of-bet' && <FooterRoute />}
                {location.pathname === '/terms' && <FooterRoute />}
                {location.pathname === '/privacy-policy' && <FooterRoute />}
                {location.pathname === '/about' && <FooterRoute />}
              </div>
              <div className="col-xl-2 col-lg-3">
                <RightSide />
              </div>
            </>
          ) : (
            <div className="col-xl-10 col-lg-9 ">
              <DashboardRoute />
              <ProfileRoute />
              <MyBetRoute />
              <MyLogsRoute />
              <WalletRoute />
              <PasswordRoute />
              <ReferRoute />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Main;
