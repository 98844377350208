import React from 'react'
import { Route, Routes } from 'react-router-dom' 
import LeftSidebar from '../dashboard/LeftSidebar'
import Dashboard from '../dashboard/Dashboard'

function DashboardRoute() {
  return (
    <>
      <Routes>
        <Route path='/dashboard' element={<Dashboard  />}/>
      </Routes>
    </>
  )
}

export default DashboardRoute
