import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Profile from '../profile/Profile'

function ProfileRoute() {
  return (
    <>
      <Routes>
        <Route path='/profile' element={<Profile/>}/>
      </Routes>
    </>
  )
}

export default ProfileRoute
