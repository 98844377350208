import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Licence from '../footerPage/Licence'
import RulesOfBet from '../footerPage/RulesOfBet'
import TermsAndCondi from '../footerPage/TermsAndCondi'
import PrivacyPolicy from '../footerPage/PrivacyPolicy'
import AboutUs from '../footerPage/AboutUs'

function FooterRoute() {
  return (
    <>
      <Routes>
        <Route path='/licence-info' element={<Licence/>}/>
        <Route path='/rules-of-bet' element={<RulesOfBet/>}/>
        <Route path='/terms' element={<TermsAndCondi/>}/>
        <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
        <Route path='/about' element={<AboutUs/>}/>
      </Routes>
    </>
  )
}

export default FooterRoute
