import React from 'react'
import circle from '../../assets/Logo/Ellipse 36.png'
import Chart from './Chart'

function Dashboard() {
  return (
    <>
       <div className='bg-dark-blue px-3'>
            <div className="d-flex align-items-center " > 
                <div className=" "> 
                    <div>
                    <h5 className='mb-0 py-1 '>Dashboard</h5>
                    </div> 
                </div>
            </div> 
       </div>

      <div className="row mt-4">
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-3">
          <div className="bg-dashboard-card  p-3">
            <div className="d-flex align-items-center " > 
              <img src={circle} alt="" width={50}/>
              <div className="ms-3"> 
                <div>
                  <p className='mb-1 text-small'>TOTAL BALANCE</p>
                </div> 
                <div className="d-flex align-items-center">
                  <h5 className="mb-0">5,030,398,171.00</h5>    
                </div>
              </div>
            </div> 
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-3">
          <div className="bg-dashboard-card  p-3">
            <div className="d-flex align-items-center " > 
              <img src={circle} alt="" width={50}/>
              <div className="ms-3"> 
                <div>
                  <p className='mb-1 text-small'>TOTAL DEPOSIT</p>
                </div> 
                <div className="d-flex align-items-center">
                  <h5 className="mb-0">8,030,45.00</h5>    
                </div>
              </div>
            </div> 
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-3">
          <div className="bg-dashboard-card  p-3">
            <div className="d-flex align-items-center " > 
              <img src={circle} alt="" width={50}/>
              <div className="ms-3"> 
                <div>
                  <p className='mb-1 text-small'>TOTAL WITHDRAW</p>
                </div> 
                <div className="d-flex align-items-center">
                  <h5 className="mb-0">4,120,39.00</h5>    
                </div>
              </div>
            </div> 
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-3">
          <div className="bg-dashboard-card  p-3">
            <div className="d-flex align-items-center " > 
              <img src={circle} alt="" width={50}/>
              <div className="ms-3"> 
                <div>
                  <p className='mb-1 text-small'>TOTAL WIN</p>
                </div> 
                <div className="d-flex align-items-center">
                  <h5 className="mb-0">1,150,398,171.00</h5>    
                </div>
              </div>
            </div> 
          </div>
        </div>
      </div>

      <div className="bg-dashboard-card my-4 p-0" style={{boxShadow:"none"}}>
        <p style={{
            background:'#1D3C99' , 
            borderTopRightRadius:'10px', 
            borderTopLeftRadius:'10px'
          }}
          className='px-3 py-2'>Winning Chart 2024</p>
        <div className='p-5'>
          <Chart/>
        </div>
      </div>
    </>
  )
}

export default Dashboard
