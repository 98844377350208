import React from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import blogDetail from '../../assets/Image/20939.webp'
import ellipse from '../../assets/Logo/Ellipse 7.png'
import recentBlog from '../../assets/Image/2777 2.png'

function BlogDetail() {
    const navigate = useNavigate()
  return (
    <>
        <div className="bg-dark-blue px-3">
            <div className="d-flex align-items-center">
                <div className='d-flex align-items-center justify-content-start'> 
                    <IoIosArrowBack  className='fs-3' onClick={()=>navigate(-1)} style={{cursor:'pointer'}}/>
                    <h5 className="mb-0 py-1 ps-2">Blogs</h5>
                </div>
            </div>
        </div>

        <div className="row mt-3">
            <div className="col-lg-7 ">
                <img src={blogDetail} alt="blog detail"  className='blogDetail-img'/>
                
                <div className="d-flex">
                    <div className="d-flex mt-3 me-2">
                        <img src={ellipse} alt="circle" width={17} height={17}/>
                        <p className='ps-2'>Admin</p>    
                    </div>
                    <div className="d-flex mt-3">
                        <img src={ellipse} alt="circle" width={17} height={17}/>
                        <p className='ps-2'>14 March 2024</p>    
                    </div>
                </div>

                <div>
                    <h5>
                        Top Betting Strategies for Beginners
                    </h5>
                    <p>
                    Betting can be an exciting way to engage with your favorite sports or games, 
                    but for beginners, it can also be overwhelming. To help you get started on the right foot, 
                    we’ve put together a comprehensive guide to the top betting strategies for beginners. 
                    With these tips, you'll learn how to bet smarter, not harder.      
                    </p><br />
                    <h5>
                        1. Understand the Basics
                    </h5>
                    <p>
                    Before placing your first bet, it’s essential to understand the fundamentals: <br />
                     *   Types of Bets: Familiarize yourself with different types of bets such as straight bets, parlays, and over/under bets. <br />
                     *   Reading Odds: Learn how to interpret betting odds, whether they’re fractional, decimal, or American. Odds represent the probability of an outcome and determine your potential payout. 
                    </p><br /> 
                    <h5>
                      2. Set a Budget
                    </h5>
                    <p>
                    Proper bankroll management is crucial:<br />
                     *   Determine Your Budget: Decide how much money you’re willing to invest in betting, and never wager more than you can afford to lose. <br />
                     *   Avoid Chasing Losses: If you’re having a losing streak, don’t try to recoup your losses by placing larger bets. Stick to your budget and strategy.
                    </p><br />
                    <h5>
                    3. Do Your Research
                    </h5>
                    <p>
                    Knowledge is power in betting: <br />
                     *   Research Teams and Players: Look into the form, injuries, and other factors that might influence the outcome of a game or match. <br />
                     *   Analyze Statistics: Use historical data and statistics to make informed decisions rather than relying on gut feelings.
                    </p><br />
                    <h5>
                    Conclusion
                    </h5>
                    <p>
                    By following these top betting strategies, beginners can start their betting journey with a solid foundation. 
                    Remember, the key to successful betting is to approach it with patience, 
                    discipline, and a willingness to learn. Enjoy the process and bet responsibly!
                    </p><br />
                </div>
            </div>
            <div className="col-lg-5">
                <div className="dropdown-widget">
                    <ul>
                        <li className="list-heading">
                            <a> 
                            Recent Blogs
                            </a>
                        </li> 
                            <div className="d-flex px-3 mt-3 mb-2">
                                <img src={recentBlog} alt="recentBlog"  width={90} height={70} className='me-2'/>
                                <div>
                                    <p className='text-12 px-1'>Put Your Money Down On The Totals. Total Is Commanly. </p>
                                    <p className='text-12 px-1'>15 May 2023</p>
                                </div>
                            </div>
                            <div className="d-flex px-3 mb-2">
                            <img src={recentBlog} alt="recentBlog"  width={90} height={70} className='me-2'/>
                                <div>
                                    <p className='text-12 px-1'>Put Your Money Down On The Totals. Total Is Commanly. </p>
                                    <p className='text-12 px-1'>15 May 2023</p>
                                </div>
                            </div>
                            <div className="d-flex px-3 pb-3">
                            <img src={recentBlog} alt="recentBlog"  width={90} height={70} className='me-2'/>
                                <div>
                                    <p className='text-12 px-1'>Put Your Money Down On The Totals. Total Is Commanly. </p>
                                    <p className='text-12 px-1'>15 May 2023</p>
                                </div>
                            </div>
                    </ul> 
                </div>
            </div>
        </div>
    </>
  )
}

export default BlogDetail
