import React from 'react'

function ChangePassword() {
  return (
    <>
      <div className="bg-dark-blue px-3">
        <div className="d-flex align-items-center">
          <div>
            <h5 className="mb-0 py-1">Changer Password</h5>
          </div>
        </div>
      </div>

      <div className="password-card mt-4">
        <form>
          <div className="d-flex flex-column align-items-center justify-content-center">
              <div className="w-pass-input mt-3">
                  <div className="mb-0">
                      <label htmlFor="current_password" className="form-label mb-1 ">Current Password</label>
                      <div className="input-group input-group-merge">
                          <input
                          type='text'
                          id="current_password"
                          className="form-control-2" 
                          placeholder="***********"  
                          autoComplete='off'
                          />  
                      </div>
                  </div>
              </div> 
              <div className="w-pass-input mt-3">
                  <div className="mb-0">
                      <label htmlFor="new_password" className="form-label mb-1 ">New Password</label>
                      <div className="input-group input-group-merge">
                          <input
                          type='email'
                          id="new_password"
                          className="form-control-2" 
                          placeholder="***********"  
                          autoComplete='off'
                          />  
                      </div>
                  </div>
              </div>
              <div className="w-pass-input mt-3">
                  <div className="mb-0">
                      <label htmlFor="confirm_passoword" className="form-label mb-1 ">Confirm Password</label>
                      <div className="input-group input-group-merge">
                          <input
                          type='text'
                          id="confirm_passoword"
                          className="form-control-2" 
                          placeholder="***********"  
                          autoComplete='off'
                          />  
                      </div>
                  </div>
              </div>
              <button
                type="button"
                className="jumbo-btn jumbo-bg-gradient  fs-6  px-5 btn-lg w-pass-input my-4  "  
              >
                Change Password
              </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default ChangePassword
