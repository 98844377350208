import React, { useEffect, useRef, useState } from 'react';
import logo from '../../assets/Logo/Asset 11.png';
import ellipse from '../../assets/Logo/Ellipse 7.png'; 
import league from '../../assets/Logo/Ellipse 7.png'; 
import map from '../../assets/Logo/maps 1.png'
import { GoEye, GoEyeClosed } from 'react-icons/go';  
import { RiEyeFill, RiEyeOffFill } from 'react-icons/ri';
import { useNavigate, useLocation } from 'react-router-dom';
import { RxCross2, RxHamburgerMenu } from 'react-icons/rx';
import { eventBus } from '../eventBus';
import { FaBraille } from 'react-icons/fa';

function Header() { 
  const navigate = useNavigate()
  const [isOpenModal, setIsOpenModal] = useState(false); 
  const [isOpenForgotPass, setIsOpenForgotPass] = useState(false);  
  const [isOpenForgotPassOTP, setIsOpenForgotPassOTP] = useState(false);  
  const [isOpenContiPass, setIsOpenContiPass] = useState(false);  
  const [isOpenSuccess, setIsOpenSuccess] = useState(false);  
  const [isSignUp, setIsSignUp] = useState(false);  
  const [isSignUpVerify, setIsSignUpVerify] = useState(false);  
  const modalRef = useRef(null); 
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [resendTimer, setResendTimer] = useState(59);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [toggleSidebarModal, setToggleSidebarModal] = useState(false);   
  const [toggleLeftSidebarModal, setToggleLeftSidebarModal] = useState(false);   
 
  useEffect(() => {
    eventBus.on('isLogin', (message) => setIsOpenModal(message));
  }, []);


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };
 
  const handleToggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const handleToggleForgotPass = () => {
    setIsOpenForgotPass(!isOpenForgotPass);
    setIsOpenModal(false)
  }; 

  const handleToggleForgotPassOTP = () => {
    setIsOpenForgotPassOTP(!isOpenForgotPassOTP);
    setIsOpenForgotPass(false)
    setIsOpenModal(false)
  };
  
  const handleToggleContinue = () => {
    setIsOpenContiPass(!isOpenContiPass);
    setIsOpenForgotPassOTP(false);
    setIsOpenForgotPass(false)
    setIsOpenModal(false)
  };

  const handleToggleSuccess = () => {
    setIsOpenSuccess(!isOpenSuccess);
    setIsOpenContiPass(false);
    setIsOpenForgotPassOTP(false);
    setIsOpenForgotPass(false)
    setIsOpenModal(false)
  };
 
  const handleFormSubmit = (e) => {
    e.preventDefault(); 
  }; 

  const handleToggleSignup = () => {
    setIsSignUp(!isSignUp);
  };  

  const handleToggleSignupVerify = () => {
    setIsSignUpVerify(!isSignUpVerify);
    setIsSignUp(false);
  };
  
  useEffect(() => {
    let intervalId;
    if (resendTimer > 0) {
      intervalId = setInterval(() => {
        setResendTimer(prevTime => prevTime - 1);
      }, 1000);
      setResendDisabled(true);
    } else {
      setResendDisabled(false);
    }

    return () => clearInterval(intervalId);
  }, [resendTimer]);

  const handleChange = (event, index) => {
    const value = event.target.value;
    const newOtp = [...otp];

    if (/[0-9]/.test(value) || value === '') {
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < 5) {
        document.getElementById(`otp__digit_${index + 1}`).focus();
      } else if (!value && index > 0) {
        document.getElementById(`otp__digit_${index - 1}`).focus();
      }
    }
  };

  const handlePaste = (event, index) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData('text');
    const newOtp = [...otp];
    const digits = pasteData.split('').filter(char => /[0-9]/.test(char));

    digits.forEach((digit, i) => {
      if (index + i < newOtp.length) {
        newOtp[index + i] = digit;
      }
    });

    setOtp(newOtp);
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsOpenModal(false); 
      setIsOpenForgotPass(false); 
      setIsOpenForgotPassOTP(false); 
      setIsOpenContiPass(false); 
      setIsOpenSuccess(false); 
      setIsSignUp(false); 
      setIsSignUpVerify(false); 
      setToggleLeftSidebarModal(false); 
      setToggleSidebarModal(false); 
    }
  };

  useEffect(() => { 
    if (isOpenModal || isOpenForgotPass || isOpenForgotPassOTP || isOpenContiPass  || isOpenSuccess || isSignUp || isSignUpVerify || toggleLeftSidebarModal || toggleSidebarModal) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpenModal , isOpenForgotPass, isOpenForgotPassOTP ,isOpenContiPass,isOpenSuccess , isSignUp , isSignUpVerify ,toggleLeftSidebarModal , toggleSidebarModal]);

  // sidebar
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(null);
  const [expandedDropdown, setExpandedDropdown] = useState(null); 
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  
  
  // Handle window resize to track screen width
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const leaguesList = [
    { name: "Dashboard", path: "/dashboard" },
    { name: "Profile", path: "/profile" },
    { name: "My Bets", path: "/my-bets" },
    {
      name: "My Logs",
      path: "/my-logs",
      tournaments: [
        { name: "Deposit Log", path: "/my-logs/deposit-log" },
        { name: "Withdraw Log", path: "/my-logs/withdraw-log" },
        { name: "Transaction Log", path: "/my-logs/transaction-log" }
      ]
    },
    {
      name: "Wallet",
      path: "/wallet",
      tournaments: [
        { name: "Deposit", path: "/wallet/deposit" },
        { name: "Withdraw", path: "/wallet/withdraw" }
      ]
    },
    { name: "Change Password", path: "/change-password" },
    { name: "Refer a Friend", path: "/refer-a-friend" },
    { name: "Log Out" }
  ];
  
  useEffect(() => {
    const currentPath = location.pathname;
    const activeTopLevel = leaguesList.find(item => item.path === currentPath)?.name || null;
    const activeSubLevel = leaguesList
      .flatMap(item => item.tournaments ? [item, ...item.tournaments] : [item])
      .find(item => item.path === currentPath)?.name || null;
  
    if (activeSubLevel) {
      setActiveItem(activeSubLevel);
      setExpandedDropdown(leaguesList.find(item => item.tournaments && item.tournaments.some(t => t.path === currentPath))?.name || null);
    } else {
      setActiveItem(activeTopLevel);
      setExpandedDropdown(null);
    }
  }, [location.pathname]);
  
  const handleItemClick = (itemName, path) => {
    if (itemName === "Log Out") {
      handleLogout();
    } else {
      setActiveItem(itemName);
      if (path) {
        navigate(path);
      }
    }
  };
  
  const toggleSidebar = () => {
    setToggleSidebarModal(!toggleSidebarModal);
  };

  const toggleLeftSidebar = () => {
    setToggleLeftSidebarModal(!toggleLeftSidebarModal);
  };
  
  const handleLogout = () => {
  navigate('/');
  };
  
  const handleDropdownToggle = (itemName) => {
  setExpandedDropdown(expandedDropdown === itemName ? null : itemName); 
  };
  
  const renderList = (list) => (
    <ul>
      {list.map((item, index) => (
        <li key={index}>
          <a
            onClick={() => {
            if (item.tournaments) {
              handleDropdownToggle(item.name);
            } else {
              handleItemClick(item.name, item.path);
              setToggleSidebarModal(false);
            }
          }}
            className={`${activeItem === item.name ? 'gradient-text' : ''} ${expandedDropdown === item.name ? "mb-4" : " "}`}
            role="button"
            aria-expanded={expandedDropdown === item.name ? "true" : "false"}
          >
            <img src={ellipse} alt="circle"  width={15} height={15}/> &nbsp; {item.name}
            {item.tournaments && (
              <i style={{marginRight:'10px'}} className={`fa fa-angle-right  ${expandedDropdown === item.name ? 'rotate ' : 'rotate1'}`} />
            )}
          </a>
          {item.tournaments && expandedDropdown === item.name && (
            <div>
              <ul>
                {item.tournaments.map((tournament, tIndex) => (
                  <li key={tIndex}>
                    <a
                      className={activeItem === tournament.name ? 'gradient-text fw-600 mt-1 ms-3' : 'mt-1 ms-3'}
                      onClick={() => {
                        handleItemClick(tournament.name, tournament.path)
                        setToggleSidebarModal(false)
                      }}
                    >
                      {tournament.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </li>
      ))}
    </ul>
  );

// sidebar end

  // leftsidebar 

  const [showAllCountries, setShowAllCountries] = useState(false);
  const [showAllLeagues, setShowAllLeagues] = useState(false);

  const countriesList = [
    { name: "India", tournaments: ["India"] },
    { name: "Australia", tournaments: ["Australia"] },
    { name: "Germany", tournaments:  ["Germany"]}, 
    { name: "Australia", tournaments:  ["Australia"]},
    { name: "Brazil", tournaments: ["Brazil"] },
    { name: "Germany", tournaments:  ["Germany"] },
  ];

  const leaguesList1 = [
    { name: "Premier League", tournaments:  ["Premier League"] },
    { name: "La Liga", tournaments:  ["La Liga"] },
    { name: "Premier League", tournaments:  ["Premier League"] },
    { name: "Liga Apertura", tournaments:  ["Liga Apertura"] },
    { name: "Bundesliga", tournaments:  ["Bundesliga"] },
    { name: "Liga Apertura", tournaments:  ["Liga Apertura"] },
  ];

  const renderList1 = (list, showAll) => (
    <div className='sidebar-inner'>
      <div className='main-sitebar'>
        <ul>
          {list.slice(0, showAll ? list.length : 4).map((item, index) => (
            <li key={index}>
              <a
                data-bs-toggle="collapse"
                href={`#collapse${index}`}
                role="button"
                aria-expanded="true"
                aria-controls="collapseExample"
              >
                <img src={ellipse} alt="circle" /> {item.name}
                <i className="fa fa-angle-right" />
              </a>
              <div className="collapse" id={`collapse${index}`}>
                <ul>
                  {item.tournaments.map((tournament, tIndex) => (
                    <li key={tIndex}>
                      <a >
                        {tournament}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );

  // leftsidebar end
  return (
    <> 
      {/* Login Modal Start */}
      {isOpenModal && (
        <>
          <div className="modal-backdrop"></div>
          <div className="modal-content" ref={modalRef}>
            <div className="container">
              <div className="modal-card w-100"> 
                <div className="card-body p-2">
                  <div className="text-center w-75 m-auto">
                    <h4 className="text-dark-50 text-center mt-3">Sign In to your account</h4>
                    <p className="text-dark-50 text-center">Don’t have an account? <span className='gradient-text pointer'>Sign Up</span></p>
                  </div>
                  <form onSubmit={handleFormSubmit}>
                    <div className="mb-3">
                      <label htmlFor="username" className="form-label mb-0">Email</label>
                      <input
                        className="form-control-1"
                        type="text"
                        id="username"
                        placeholder="Your Email"  
                        autoComplete='off'
                      />
                    </div>
                    <div className="mb-2">
                      <label htmlFor="password" className="form-label mb-0">Password</label>
                      <div className="input-group input-group-merge">
                        <input
                          type='password'
                          id="password"
                          className="form-control-1" 
                          placeholder="Your Password" 
                          autoComplete='off'
                        /> 
                      </div>
                    </div>
                    <div className="mb-3 form-check d-flex justify-content-between">
                      <div className="custom-checkbox-container">
                        <input
                          type="checkbox"
                          id="custom-checkbox"
                          className="custom-checkbox"
                        />
                        <label htmlFor="custom-checkbox" className="custom-checkbox-label">Remember Me</label>
                      </div>
                      <a className="forgot-password gradient-text" onClick={handleToggleForgotPass}>Forgot Password?</a>
                    </div>

                    <div className="col-12 text-center mt-5">
                      <button
                        type="submit"
                        className="login-btn login-bg-gradient  fs-6 px-5 btn-lg mb-2" 
                        onClick={() => {
                          setIsLogin(true)
                          setIsOpenModal(false)
                        }}
                      >
                        Start Playing
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {isOpenForgotPass && (
        <>
          <div className="modal-backdrop"></div>
          <div className="modal-content" ref={modalRef}>
            <div className="container">
              <div className="modal-card w-100"> 
                <div className="card-body p-2">
                  <div className="text-center w-75 m-auto">
                    <h4 className="text-dark-50 text-center mt-3">Forgot Password</h4>
                    <p className="text-dark-50 text-center">Don’t worry we’ll send you the instructions to reset it. </p>
                  </div>
                  <form onSubmit={handleFormSubmit}>
                    <div className="mb-4">
                      <label htmlFor="username" className="form-label mb-0">Email</label>
                      <input
                        className="form-control-1"
                        type="text"
                        id="username"
                        placeholder="demo@gmail.com"  
                        autoComplete='off'
                      />
                    </div>  
                    <div className="col-12 text-center  mb-2">
                      <button
                        type="submit"
                        className="login-btn login-bg-gradient  fs-6 px-5 btn-lg mb-2" 
                        onClick={handleToggleForgotPassOTP}
                      >
                        Verify Email
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {isOpenForgotPassOTP && (
        <>
          <div className="modal-backdrop"></div>
          <div className="modal-content" ref={modalRef}>
            <div className="container">
              <div className="modal-card w-100"> 
                <div className="card-body p-2">
                  <div className="text-center w-75 m-auto mb-4">
                    <h4 className="text-dark-50 text-center mt-3">Verify Email Address</h4>
                    <p className="text-dark-50 text-center">A 6 digit verification code has been send to demo****@gmail.com. </p>
                  </div>
                  <form onSubmit={handleFormSubmit}>
                    <div className='otp-container my-3'>
                      {otp.map((digit, index) => (
                        <input
                          key={index}
                          id={`otp__digit_${index}`}
                          type='text'
                          className={`otp__digit rounded-2`}
                          maxLength='1'
                          value={digit}
                          autoComplete='off'
                          onChange={(e) => handleChange(e, index)}
                          onPaste={(e) => handlePaste(e, index)}
                        />
                      ))}
                    </div> 
                    <p className="text-dark-50 text-center mb-4">Didn’t receive the code?  <span className='gradient-text pointer'>Resend</span></p>
                    <div className="col-12 text-center  mb-2">
                      <button
                        type="submit"
                        className="login-btn login-bg-gradient  fs-6 px-5 btn-lg mb-2" 
                        onClick={handleToggleContinue}
                      >
                        Continue
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {isOpenContiPass && (
        <>
          <div className="modal-backdrop"></div>
          <div className="modal-content" ref={modalRef}>
            <div className="container">
              <div className="modal-card w-100"> 
                <div className="card-body p-2">
                  <div className="text-center w-75 m-auto mb-4">
                    <h4 className="text-dark-50 text-center mt-3">Set a New Passowrd</h4>
                    <p className="text-dark-50 text-center">Enter a new password below to complete the reset password process.</p>
                  </div>
                  <form onSubmit={handleFormSubmit}>
                    <div className="mb-0">
                      <label htmlFor="password" className="form-label mb-0">New Password</label>
                      <div className="input-group input-group-merge">
                        <input
                          type={showPassword ? 'text' : 'password'}
                          id="password"
                          className="form-control-1" 
                          placeholder="**********" 
                          // placeholder="New Password" 
                          autoComplete='off'
                        /> 
                        <span className="input-group-text" onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}>
                            {showPassword ? <RiEyeOffFill /> : <RiEyeFill />}
                          </span>
                      </div>
                    </div>
                    <div className="">
                      <label htmlFor="password" className="form-label mb-0">Confirm Password</label>
                      <div className="input-group input-group-merge">
                        <input
                          type={showPassword1 ? 'text' : 'password'}
                          id="password"
                          className="form-control-1" 
                          placeholder="**********" 
                          // placeholder="Confirm Password" 
                          autoComplete='off'
                        /> 
                          <span className="input-group-text" onClick={togglePasswordVisibility1} style={{ cursor: 'pointer' }}>
                            {showPassword1 ? <RiEyeOffFill /> : <RiEyeFill />}
                          </span>
                      </div>
                    </div>
                    <div className="col-12 text-center  mb-2">
                      <button
                        type="submit"
                        className="login-btn login-bg-gradient  fs-6 px-5 btn-lg mb-2" 
                        onClick={handleToggleSuccess}
                      >
                        Change Password
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {isOpenSuccess && (
        <>
          <div className="modal-backdrop"></div>
          <div className="modal-content" ref={modalRef}>
            <div className="container d-flex flex-column justify-content-end"  >
              <div className="modal-card w-100" style={{position: 'relative', bottom: '-50px'}}>
                <div className="card-body p-2 ">
                  <div className="text-center m-auto mb-4">
                    <h4 className="text-dark-50 text-center mt-3">Password change successfully</h4>
                  </div>
                  <p className="text-dark-50 text-center">Your password has been successfully changed, you can now login with your new password.</p>
                  <div className="col-12 text-center mb-2">
                    <button
                      type="submit"
                      className="login-btn login-bg-gradient fs-6 px-5 btn-lg mb-2"
                      onClick={()=>{
                        setIsLogin(true)
                        setIsOpenSuccess(false)
                      }}
                    >
                      Login Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div> 
        </>
      )}
      {/* Login Modal End */}

      {/* Sign Up Modal Start */}
      {isSignUp && (
        <>
          <div className="modal-backdrop"></div>
          <div className="modal-content" ref={modalRef}>
            <div className="container">
              <div className="modal-card w-100"> 
                <div className="card-body p-2">
                  <div className="text-center w-75 m-auto">
                    <h4 className="text-dark-50 text-center mt-3">Create New Account </h4>
                    <p className="text-dark-50 text-center">Already have an account?  <span className='gradient-text pointer'>Login</span></p>
                  </div>
                  <form onSubmit={handleFormSubmit}>
                    <div className="mb-3">
                      <label htmlFor="username" className="form-label mb-0">Username</label>
                      <input
                        className="form-control-1"
                        type="text"
                        id="username"
                        placeholder="Your Username"  
                        autoComplete='off'
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label mb-0">Email</label>
                      <input
                        className="form-control-1"
                        type="email"
                        id="email"
                        placeholder="Your Email"  
                        autoComplete='off'
                      />
                    </div>
                    <div className="mb-2">
                      <label htmlFor="password" className="form-label mb-0">Password</label>
                      <div className="input-group input-group-merge">
                        <input
                          type='password'
                          id="password"
                          className="form-control-1" 
                          placeholder="Your Password" 
                          autoComplete='off'
                        /> 
                      </div>
                    </div>
                    <div className="mb-3 form-check d-flex justify-content-between flex-column">
                      <div className="custom-checkbox-container">
                        <input
                          type="checkbox"
                          id="custom-checkbox"
                          className="custom-checkbox"
                        />
                        <label htmlFor="custom-checkbox" className="custom-checkbox-label text-small">I certify that i am at least 18 years of age</label>
                      </div>

                       <div className="custom-checkbox-container mt-1">
                        <input
                          type="checkbox"
                          id="custom-checkbox1"
                          className="custom-checkbox"
                        />
                        <label htmlFor="custom-checkbox1" className="custom-checkbox-label text-small">I agree to the&nbsp;<span className='gradient-text pointer'>Privacy Policy</span></label>
                      </div> 
                    </div>

                    <div className="col-12 text-center mt-5">
                      <button
                        type="submit"
                        className="login-btn login-bg-gradient  fs-6 px-5 btn-lg mb-2" 
                        onClick={handleToggleSignupVerify}
                      >
                        Submit now
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )} 
      {isSignUpVerify && (
        <>
          <div className="modal-backdrop"></div>
          <div className="modal-content" ref={modalRef}>
            <div className="container">
              <div className="modal-card w-100"> 
                <div className="card-body p-2">
                  <div className="text-center w-75 m-auto mb-4">
                    <h4 className="text-dark-50 text-center mt-3">Verify Email Address</h4>
                    <p className="text-dark-50 text-center">A 6 digit verification code has been send to demo****@gmail.com. </p>
                  </div>
                  <form onSubmit={handleFormSubmit}>
                    <div className='otp-container my-3'>
                      {otp.map((digit, index) => (
                        <input
                          key={index}
                          id={`otp__digit_${index}`}
                          type='text'
                          className={`otp__digit rounded-2`}
                          maxLength='1'
                          value={digit}
                          autoComplete='off'
                          onChange={(e) => handleChange(e, index)}
                          onPaste={(e) => handlePaste(e, index)}
                        />
                      ))}
                    </div> 
                    <p className="text-dark-50 text-center mb-4">Didn’t receive the code?  <span className='gradient-text pointer'>Resend</span></p>
                    <div className="col-12 text-center  mb-2">
                      <button
                        type="submit"
                        className="login-btn login-bg-gradient  fs-6 px-5 btn-lg mb-2" 
                        onClick={()=>{
                          setIsLogin(true)
                          setIsSignUpVerify(false)
                        }}
                      >
                        Continue
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )} 
      {/* Sign Up Modal Start End */}

      {/* responsive modal show */}
      {toggleSidebarModal && (
        <> 
          <div className={`  ${toggleSidebarModal && (windowWidth < 992) ? 'modal-backdrop' : ''}`}></div>
          <div className={` sidebar-menu ${toggleSidebarModal && (windowWidth < 992) ? 'active' : ''}`} 
            style={{width:'35%'}}
            id="sidebar-menu" ref={modalRef}>
            <div className='sidebar-menu-close d-flex align-items-center justify-content-center mb-4 p-0' 
              onClick={()=>setToggleSidebarModal(false)}
            >
            <RxCross2 className='fs-4' />
            </div>
            <ul className='mt-5 single-sitebar-menu '> 
              {renderList(leaguesList)}
            </ul>
          </div>
        </>
      )}
      
      {toggleLeftSidebarModal && (
        <> 
          <div className={`  ${toggleLeftSidebarModal && (windowWidth < 992) ? 'modal-backdrop' : ''}`}></div>
          <div className={` sidebar-menu ${toggleLeftSidebarModal && (windowWidth < 992) ? 'active' : ''}`} id="sidebar-menu-mobile"  ref={modalRef}>
          <div className='sidebar-menu-close d-flex align-items-center justify-content-center mb-4 p-0  ' 
              style={{background:'none'}}
              onClick={()=>setToggleLeftSidebarModal(false)}
            >
            <RxCross2  className='fs-4'/>
          </div>
          <div className={` sidebar-inner`} style={{ padding: '62px 38px 50px 0 '}} >
            <div className= 'main-sitebar' >
                <div className="dropdown-widget">
                <ul>
                  <li className="list-heading">
                    <a>
                      <img src={map} alt="img" className="me-1" style={{ width: '28px', height: '28px' }} />{" "}
                      <h6 className='mt-1 mb-0'>Countries</h6>
                    </a>
                  </li>
                  {renderList1(countriesList, showAllCountries)}
                </ul>
                <div className="footer">
                  <button onClick={() => setShowAllCountries(!showAllCountries)} className="btn-viewall text-end pe-4 text-small">
                    {showAllCountries ? "Show Less" : "View All"}
                  </button>
                </div>
              </div>

                {/* Leagues Dropdown */}
              <div className="dropdown-widget">
                <ul>
                  <li className="list-heading">
                    <a>
                      <img src={league} alt="img" className="me-1" style={{ width: '28px', height: '28px', borderRadius: '0' }} />{" "}
                      <h6 className='mt-1 mb-0'>Leagues</h6>
                    </a>
                  </li>
                  {renderList1(leaguesList1, showAllLeagues)}
                </ul>
                <div className="footer">
                  <button onClick={() => setShowAllLeagues(!showAllLeagues)} className="btn-viewall text-end pe-4 text-small">
                    {showAllLeagues ? "Show Less" : "View All"}
                  </button>
                </div>
              </div>  

            </div>
          </div>
          </div>
        </>
      )}

      {/* responsive modal show end */}
      
    
      
      <div className="navbar-top bg-black-2">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center"> 
            <div className="logo" onClick={()=>navigate('/')} style={{cursor:'pointer'}}>
              <img src={logo} alt="logo" className='logo-header'/>
            </div>
             
            <div className='d-flex align-items-center'> 
              {
               ( isLogin && windowWidth> 992) && <p className='mb-0 me-3 '>Balance: $4567 <br /><span style={{marginLeft:'11px'}}>Promo: $4567</span></p> 
              } 
            <div className="buttons">
              <ul className="topbar-right list-unstyled d-flex m-0 p-0">
                <li>
                  {
                    !isLogin && 
                    <> 
                    
                      <button className="jumbo-header-btn me-3 jumbo-header-border" onClick={handleToggleModal} >
                        Login
                      </button>
                      <button className="jumbo-header-btn jumbo-header-gradient" onClick={handleToggleSignup}>Sign Up</button>
                    </>
                  }
                   {
                      windowWidth < 992 && 
                   <button className='braille-btn ' onClick={toggleLeftSidebar}>
                      <FaBraille />
                    </button>
                    }
                  {
                    isLogin && 
                    <>
                    <a className="navbar-toggler d-lg-none" onClick={toggleSidebar}>
                    <RxHamburgerMenu className='fs-2'  style={{cursor:'pointer', color:'#1D3C99'}}/>
                    </a>
                     {
                      windowWidth> 992 && 
                      <>
                         <button className="jumbo-header-btn me-3 jumbo-header-gradient" onClick={()=>navigate('/dashboard')}>Dashboard</button>
                      <button className="jumbo-header-btn me-3 jumbo-header-gradient" onClick={()=>navigate('/wallet/deposit')}>Deposit</button>
                      <button className="jumbo-header-btn me-3 jumbo-header-gradient" onClick={()=>navigate('/wallet/withdraw')}>Withdraw</button>
                      <button className="jumbo-header-btn jumbo-logout-btn "><span className='gradient-text' onClick={()=>{
                        setIsLogin(false)
                        navigate('/')
                      }}>Logout</span></button>
                      </>
                     }
                    </>
                  }
                </li>
              </ul>
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
