import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ChangePassword from '../changePassword/ChangePassword'

function PasswordRoute() {
  return (
    <>
      <Routes>
        <Route path='/change-password' element={<ChangePassword/>}/>
      </Routes>
    </>
  )
}

export default PasswordRoute
