import React, { useState, useEffect } from 'react';
import { IoIosArrowForward } from "react-icons/io"; 
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import ReactPaginate from 'react-paginate';

const dummyData = [
  {
    id: 1,
    state: 1,
    event: "City Bank",
    amout: "$563",
    result: "PROCESSING",
    transactionID: "618O6B8BUOYV", 
    type:'Withdraw',
    time: "7 Aug | 7:30 pm",
  },   
  {
    id: 2,
    state: 2,
    event: "City Bank",
    amout: "$563",
    result: "SUCCESS",
    transactionID: "618O6B8BUOYV", 
    type:'Deposit',
    time: "7 Aug | 7:30 pm",
  },  
  {
    id: 3,
    state: 1,
    event: "City Bank",
    amout: "$563",
    result: "PROCESSING",
    transactionID: "618O6B8BUOYV", 
    type:'Withdraw',
    time: "7 Aug | 7:30 pm",
  },  
  {
    id: 4,
    state: 3,
    event: "City Bank",
    amout: "$563",
    result: "FAIL",
    transactionID: "618O6B8BUOYV", 
    type:'Deposit',
    time: "7 Aug | 7:30 pm",
  },  
  {
    id: 5,
    state: 1,
    event: "City Bank",
    amout: "$563",
    result: "PROCESSING",
    transactionID: "618O6B8BUOYV", 
    type:'Withdraw',
    time: "7 Aug | 7:30 pm",
  },  
  {
    id: 6,
    state: 1,
    event: "City Bank",
    amout: "$563",
    result: "PROCESSING",
    transactionID: "618O6B8BUOYV", 
    type:'Deposit',
    time: "7 Aug | 7:30 pm",
  },  
  {
    id: 7,
    state: 2,
    event: "City Bank",
    amout: "$563",
    result: "SUCCESS",
    transactionID: "618O6B8BUOYV", 
    type:'Withdraw',
    time: "7 Aug | 7:30 pm",
  },  
  {
    id: 8,
    state: 3,
    event: "City Bank",
    amout: "$563",
    result: "FAIL",
    transactionID: "618O6B8BUOYV", 
    type:'Deposit',
    time: "7 Aug | 7:30 pm",
  },  
  {
    id: 9,
    state: 1,
    event: "City Bank",
    amout: "$563",
    result: "PROCESSING",
    transactionID: "618O6B8BUOYV", 
    type:'Withdraw',
    time: "7 Aug | 7:30 pm",
  },  
  {
    id: 10,
    state: 3,
    event: "City Bank",
    amout: "$563",
    result: "FAIL",
    transactionID: "618O6B8BUOYV", 
    type:'Deposit',
    time: "7 Aug | 7:30 pm",
  },  
  {
    id: 11,
    state: 2,
    event: "City Bank",
    amout: "$563",
    result: "SUCCESS",
    transactionID: "618O6B8BUOYV", 
    type:'Withdraw',
    time: "7 Aug | 7:30 pm",
  },  
  {
    id: 12,
    state: 2,
    event: "City Bank",
    amout: "$563",
    result: "SUCCESS",
    transactionID: "618O6B8BUOYV", 
    type:'Deposit',
    time: "7 Aug | 7:30 pm",
  },  
];

function TransactionLogs() {
  const [page, setPage] = useState(0);
  const [itemsPerPage] = useState(6); 
  const [totalPages, setTotalPages] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);

  useEffect(() => {
    const offset = page * itemsPerPage;
    const paginatedItems = dummyData.slice(offset, offset + itemsPerPage);
    setCurrentItems(paginatedItems);
    setTotalPages(Math.ceil(dummyData.length / itemsPerPage));
  }, [page, itemsPerPage]);

  const handlePageChange = ({ selected }) => {
    setPage(selected);
  };

  return (
    <>
      <div className="bg-dark-blue px-3">
        <div className="d-flex align-items-center">
          <div>
            <h5 className="mb-0 py-1">Transaction Log</h5>
          </div>
        </div>
      </div>
      
      <div className="bg-dark-blue px-3 mt-3">
        <div className="d-flex align-items-center">
          <div className="input-group input-group-merge">
            <select
              id="transaction"
              className="form-control-2 py-4 text-white rounded"
              style={{background:'#102460'}}
              autoComplete="off"
              >
              <option value="">All Transaction</option>
              <option value="deposit">Deposit</option>
              <option value="withdraw">Withdraw</option>
            </select>
            <IoIosArrowForward 
              style={{
                position: 'absolute',
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
              }}
            />
          </div>
          <div>
            <button
              type="button"
              className="jumbo-btn jumbo-bg-gradient  fs-6 px-5 btn-lg  ms-3"  
            >
              Search
            </button>
          </div>
        </div>
      </div>

      <div className="table-responsive mt-2" id="basic-datatable-preview">
        <table
          id="paginated-list"
          className="table nowrap w-100 text-center spacing-table"
        >
          <thead>
            <tr>
              <th style={{ borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px',width: "100.597px" }}>Sr</th>
              <th style={{ width: "200.597px" }}>Gateway name</th>
              <th style={{ width: "200.597px" }}>Transaction ID</th>  
              <th style={{ width: "150.597px" }}>Amount</th>
              <th style={{ width: "200.597px" }}>Status</th>
              <th style={{ width: "180.597px" }}>Type</th>
              <th style={{ borderTopRightRadius: '10px', borderBottomRightRadius: '10px',width: "200.597px" }}>Time</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item, index) => (
              <tr key={item.id}>
                <td style={{ width: "100.597px" }} className="align-middle">{index + 1 + page * itemsPerPage}</td> 
                <td style={{ width: "200.597px" }} className="align-middle">{item.event}</td>
                <td style={{ width: "200.597px" }} className="align-middle">{item.transactionID}</td>
                <td style={{ width: "150.597px" }} className="align-middle">{item.amout}</td>
                <td style={{ width: "200.597px" }} className="align-middle">
                  {item.state ===1 && <span className={`badge badge-warning-lighten`}>{item.result} </span>}
                  {item.state ===2 && <span className={`badge badge-success-lighten`}>{item.result} </span>}
                  {item.state ===3 && <span className={`badge badge-danger-lighten`}>{item.result} </span>} 
                </td>
                <td style={{ width: "180.597px" }} className="align-middle">{item.type}</td>
                <td style={{ width: "200.597px" }} className="align-middle">{item.time}</td> 
              </tr>
            ))}
          </tbody>
        </table>
          </div>
        <div className="float-end mb-4">
          <ReactPaginate
            className="pagination"
            onPageChange={handlePageChange}
            pageCount={totalPages}
            previousLabel={
              <div className="d-flex align-items-center pe-2">
                <MdKeyboardArrowLeft className="fs-4" />
                <span>Back</span>
              </div>
            }
            nextLabel={
              <div className="d-flex align-items-center ps-2">
                <span>Next</span>
                <MdKeyboardArrowRight className="fs-4" />
              </div>
            }
            marginPagesDisplayed={2}
            pageRangeDisplayed={1}
            breakLabel={'..'}
            containerClassName={'pagination-container'}
            activeClassName={'pagination-active-page'}
          />
        </div>
    </>
  );
}

export default TransactionLogs;
