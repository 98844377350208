import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Dashboard from '../dashboard/Dashboard'
import LeftSidebar from '../dashboard/LeftSidebar'

function  DashboardLeft() {
  return (
    <>
    <Routes>
        <Route path='/dashboard' element={<LeftSidebar/>}/>
        <Route path='/profile' element={<LeftSidebar/>}/>
        <Route path='/my-bets' element={<LeftSidebar/>}/>
        <Route path='/my-logs/deposit-log' element={<LeftSidebar/>}/>
        <Route path='/my-logs/withdraw-log' element={<LeftSidebar/>}/>
        <Route path='/my-logs/transaction-log' element={<LeftSidebar/>}/>
        <Route path='/wallet/deposit' element={<LeftSidebar/>}/>
        <Route path='/wallet/deposit-bank' element={<LeftSidebar/>}/>
        <Route path='/wallet/withdraw' element={<LeftSidebar/>}/>
        <Route path='/change-password' element={<LeftSidebar/>}/>
        <Route path='/refer-a-friend' element={<LeftSidebar/>}/>
    </Routes>
    </>
  )
}

export default DashboardLeft
