import React from 'react'
import paypal from '../../assets/Image/PayPal-Logo-PNG2 1.png'
import coinpayment from '../../assets/Image/pngfind.com-centurylink-logo-png-6483786 1.png'
import paytm from '../../assets/Image/205d227d9de5dfd223166df39c609290 1.png'
import stripe from '../../assets/Image/Vector.png'
import { useNavigate } from 'react-router-dom'

function DepositBank() {
    const navigate = useNavigate()
  return (
    <>
      <div className="bg-dark-blue px-3">
        <div className="d-flex align-items-center">
          <div>
            <h5 className="mb-0 py-1">Deposit</h5>
          </div>
        </div>
      </div>
      <div className="bg-dark-blue px-3 mt-4 mb-5">
      <button
            type="button"
            className="jumbo-btn jumbo-bg-gradient fs-6 px-5 mt-2 btn-lg  btn-small-device" 
            onClick={()=>navigate(-1)}
        >
            Back
        </button>
        <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                <div className='bg-deposit-card mt-4'>
                    <div className='bg-white rounded p-3 d-flex align-items-center justify-content-center' style={{height:'90px'}}>
                        <img src={paypal} alt="paypal" width={90}  />
                    </div>
                    <p className='text-center mt-3 mb-0'><i>PAYPAL</i></p>
                </div>  
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                <div className='bg-deposit-card mt-4'>
                    <div className='bg-white rounded p-3 d-flex align-items-center justify-content-center' style={{height:'90px'}}>
                        <img src={coinpayment} alt="paypal"    />
                    </div>
                    <p className='text-center mt-3 mb-0'><i>COINPAYMENTS</i></p>
                </div>  
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                <div className='bg-deposit-card mt-4'>
                    <div className='bg-white rounded p-3 d-flex align-items-center justify-content-center' style={{height:'90px'}}>
                        <img src={paytm} alt="paypal" width={90} />
                    </div>
                    <p className='text-center mt-3 mb-0'><i>PAYTM</i></p>
                </div>  
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                <div className='bg-deposit-card mt-4'>
                    <div className='bg-white rounded p-3 d-flex align-items-center justify-content-center' style={{height:'90px'}}>
                        <img src={stripe} alt="paypal" width={90} />
                    </div>
                    <p className='text-center mt-3 mb-0'><i>STRIPE</i></p>
                </div>  
            </div> 
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                <div className='bg-deposit-card mt-4'>
                    <div className='bg-white rounded p-3 d-flex align-items-center justify-content-center' style={{height:'90px'}}>
                        <img src={paypal} alt="paypal" width={90}  />
                    </div>
                    <p className='text-center mt-3 mb-0'><i>PAYPAL</i></p>
                </div>  
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                <div className='bg-deposit-card mt-4'>
                    <div className='bg-white rounded p-3 d-flex align-items-center justify-content-center' style={{height:'90px'}}>
                        <img src={coinpayment} alt="paypal"    />
                    </div>
                    <p className='text-center mt-3 mb-0'><i>COINPAYMENTS</i></p>
                </div>  
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                <div className='bg-deposit-card mt-4'>
                    <div className='bg-white rounded p-3 d-flex align-items-center justify-content-center' style={{height:'90px'}}>
                        <img src={paytm} alt="paypal" width={90} />
                    </div>
                    <p className='text-center mt-3 mb-0'><i>PAYTM</i></p>
                </div>  
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                <div className='bg-deposit-card mt-4'>
                    <div className='bg-white rounded p-3 d-flex align-items-center justify-content-center' style={{height:'90px'}}>
                        <img src={stripe} alt="paypal" width={90} />
                    </div>
                    <p className='text-center mt-3 mb-0'><i>STRIPE</i></p>
                </div>  
            </div>
        </div>

      </div>
    </>
  )
}

export default DepositBank
