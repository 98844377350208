import React, { useState } from 'react';
import image from '../../assets/Image/Young businessman happy expression.png';

function Profile() {
    const [profileImage, setProfileImage] = useState(image);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setProfileImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <>
            <div className='bg-dark-blue px-3'>
                <div className="d-flex align-items-center">
                    <div>
                        <h5 className='mb-0 py-1 '>Profile</h5>
                    </div>
                </div>
            </div>

            <div className="bg-profile-card my-4">
                <div className="row"> 
                    <div className="col-lg-3">
                        <div className="d-flex  align-items-center flex-column w-100 px-2 profile-img">
                            <img src={profileImage} alt="profile" width={300}  height={255}/>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                style={{ display: 'none' }}
                                id="imagePicker"
                            />
                            <label htmlFor="imagePicker" style={{ cursor: 'pointer' }} className='choose-profile mt-4 w-100 text-center'> Choose File </label>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <form >
                            <div className="row">
                                <div className="col-lg-6 mt-3">
                                    <div className="mb-0">
                                        <label htmlFor="name" className="form-label mb-0 ">Name</label>
                                        <div className="input-group input-group-merge">
                                            <input
                                            type='text'
                                            id="name"
                                            className="form-control-2" 
                                            defaultValue="Maurice Carter"  
                                            autoComplete='off'
                                            />  
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-3">
                                    <div className="mb-0">
                                        <label htmlFor="gender" className="form-label mb-0">Gender</label>
                                        <div className="input-group input-group-merge">
                                            <select
                                                id="gender"
                                                className="form-control-2"
                                                autoComplete="off"
                                            >
                                                <option value="">Select Gender</option>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-3">
                                    <div className="mb-0">
                                        <label htmlFor="email" className="form-label mb-0 ">Email</label>
                                        <div className="input-group input-group-merge">
                                            <input
                                            type='email'
                                            id="email"
                                            className="form-control-2" 
                                            defaultValue="maurice@gmail.com"  
                                            autoComplete='off'
                                            />  
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-3">
                                    <div className="mb-0">
                                        <label htmlFor="phone_number" className="form-label mb-0 ">Phone Number</label>
                                        <div className="input-group input-group-merge">
                                            <input
                                            type='text'
                                            id="phone_number"
                                            className="form-control-2" 
                                            defaultValue="+(241) 234 567 "  
                                            autoComplete='off'
                                            />  
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-3">
                                    <div className="mb-0">
                                        <label htmlFor="address" className="form-label mb-0 ">Address</label>
                                        <div className="input-group input-group-merge">
                                            <input
                                            type='text'
                                            id="address"
                                            className="form-control-2" 
                                            defaultValue="325 W Sunset Dr"  
                                            autoComplete='off'
                                            />  
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-3">
                                    <div className="mb-0">
                                        <label htmlFor="zip_code" className="form-label mb-0 ">Zip Code</label>
                                        <div className="input-group input-group-merge">
                                            <input
                                            type='text'
                                            id="zip_code"
                                            className="form-control-2" 
                                            defaultValue="53189"  
                                            autoComplete='off'
                                            />  
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-3">
                                    <div className="mb-0">
                                        <label htmlFor="city" className="form-label mb-0 ">City</label>
                                        <div className="input-group input-group-merge">
                                            <input
                                            type='text'
                                            id="city"
                                            className="form-control-2" 
                                            defaultValue="Waukesha"  
                                            autoComplete='off'
                                            />  
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-3">
                                    <div className="mb-0">
                                        <label htmlFor="name" className="form-label mb-0 ">Country</label>
                                        <div className="input-group input-group-merge">
                                            <input
                                            type='text'
                                            id="name"
                                            className="form-control-2" 
                                            defaultValue="United States"  
                                            autoComplete='off'
                                            />  
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className=" float-lg-end  float-md-end float-sm-end mt-4 mb-0 ">
                                <button
                                    type="button"
                                    className="jumbo-btn jumbo-bg-gradient  fs-6 px-5 btn-lg btn-small-device"  
                                >
                                    Update Profile
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Profile;
