import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Deposit from '../wallet/Deposit'
import Withdraw from '../wallet/Withdraw'
import DepositBank from '../wallet/DepositBank'

function WalletRoute() {
  return (
    <>
      <Routes>
        <Route path='/wallet/deposit' element={<Deposit/>}/>
        <Route path='/wallet/deposit-bank' element={<DepositBank/>}/>
        <Route path='/wallet/withdraw' element={<Withdraw/>}/>
      </Routes>
    </>
  )
}

export default WalletRoute
