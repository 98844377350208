import React, { useState } from 'react'
import logo from '../../assets/Logo/Asset 11.png'
import facebook from '../../assets/Logo/facebook (2).png'
import linkedin from '../../assets/Logo/linkdein-1.png'
import twitter from '../../assets/Logo/twitter.svg'
import instagram from '../../assets/Logo/insatgram-1.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { RiFacebookFill } from 'react-icons/ri'
import { FaInstagram, FaLinkedinIn } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'

function Footer() {
  const navigate = useNavigate() 
  const location = useLocation()
 
  return (
    <>
      <footer className="footer-area bg-black-3 pd-top-100 mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="widget widget_about">
                <div className="thumb">
                  <img src={logo} alt="img" />
                </div>
                <div className="details">
                  <p className='text-footer-blue'>
                  Jumbo Bet Essentially An Online Platform Where Gamblers Can Place Bets Directly Against One Another, Bypassing Traditional Bookmakers.
                  </p>
                  <ul className="social-media ">
                    <h5  className='text-footer-blue fw-600 mb-3'>
                    <i>Follow us</i>
                    </h5>
                    <li>
                      {/* <img src={facebook} alt="icon"width={40}  className='me-1'/> */}
                      <div className='jumbo-bg-gradient rounded-circle' style={{cursor:'pointer'}}>
                        <RiFacebookFill  className='fs-1 p-1'/>
                      </div>
                    </li>
                    <li>
                      {/* <img src={linkedin} alt="icon" width={40} className='me-1'/> */}
                      <div className='jumbo-bg-gradient rounded-circle' style={{cursor:'pointer'}}>
                        <FaLinkedinIn  className='fs-1 p-2'/>
                      </div>
                    </li>
                    <li>
                      {/* <img src={twitter} alt="icon" width={40} className='me-1'/> */}
                      <div className='jumbo-bg-gradient rounded-circle' style={{cursor:'pointer'}}>
                        <FaXTwitter   className='fs-1 p-2'/>
                      </div>
                    </li>
                    <li>
                      {/* <img src={instagram} alt="icon" width={40} className='me-1'/> */}
                      <div className='jumbo-bg-gradient rounded-circle' style={{cursor:'pointer'}}>
                        <FaInstagram  className='fs-1 p-1'/>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 ps-xl-5">
              <div className="widget widget_nav_menu">
                <h5 className="widget-title text-footer-blue fw-600"><i>Company Policy</i></h5>
                <ul>
                  <li>
                    <a
                      className={`text-footer-blue ${location.pathname === '/licence-info' ? 'footer-active' : ''}`}
                      onClick={() => navigate('/licence-info')}
                    >
                      Licences Info
                      </a>
                  </li>
                  <li>
                    <a
                      className={`text-footer-blue ${location.pathname === '/rules-of-bet' ? 'footer-active' : ''}`}
                      onClick={() => navigate('/rules-of-bet')}
                    >
                      Rules Of Bet
                      </a>
                  </li>
                  <li>
                    <a
                      className={`text-footer-blue ${location.pathname === '/terms' ? 'footer-active' : ''}`}
                      onClick={() => navigate('/terms')}
                    >
                      Terms Of Service
                    </a>
                  </li>
                  <li>
                    <a 
                      className={`text-footer-blue ${location.pathname === '/privacy-policy' ? 'footer-active' : ''}`}
                      onClick={() => navigate('/privacy-policy')}
                    >
                      Privacy Policy
                      </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 ps-xl-5">
              <div className="widget widget_nav_menu">
                <h5 className="widget-title text-footer-blue fw-600"><i>Useful Links</i></h5>
                <ul>
                  <li>
                    <a
                      className={`text-footer-blue ${location.pathname === '/about' ? 'footer-active' : ''}`}
                      onClick={() => navigate('/about')}
                    >
                      About Us
                    </a>
                  </li>
                  <li>
                    <a
                      className={`text-footer-blue ${(location.pathname === '/all-blog' || location.pathname === '/blog-detail') ? 'footer-active' : ''}`}
                      onClick={() => navigate('/all-blog')}
                    >
                      Blogs
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="widget widget-recent-post">
                <h5 className="widget-title text-footer-blue fw-600"><i>Contact us</i></h5>
                <div className="widget widget_contact">
                  <ul className="details">
                    <li>
                      <div className="media">
                        <div className="media-body text-footer-blue">
                          <span className='fw-600 fs-6'>Address</span>
                          <p className="mt-1 mb-0 text-footer-blue">Bianca Stream, Shaunnabury</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="media">
                        <div className="media-body text-footer-blue">
                          <span className='fw-600 fs-6'>Email</span>
                          <p className="mt-1 mb-0 text-footer-blue">support@example.com</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="media">
                        <div className="media-body text-footer-blue">
                          <span className='fw-600 fs-6'>Call Us</span>
                          <p className="mt-1 mb-0 text-footer-blue">+91 01234 56789</p>
                        </div>
                      </div>
                      <hr className='footer-hr' />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="footer-bottom text-center">
            <p className='text-footer-blue'>2024 © All Rights Reserved</p>
          </div>
        </div>
      </footer>

    </>
  )
}

export default Footer
