import React, { useEffect, useState } from 'react'
import mainContentImage from '../../assets/Image/1241 1.jpg'
import teamOne from '../../assets/Image/Asset 1 1.png'
import iii from '../../assets/Image/20939 1.png'
import teamTwo from '../../assets/Image/Asset 3@3x 1.png'
import teamthree from '../../assets/Image/Asset 5@3x.png'
import teamfour from '../../assets/Image/Asset 4@3x.png'
import ellipse from '../../assets/Logo/Ellipse 7.png'
import image from '../../assets/Image/1927 2.jpg'
import { IoEyeOutline } from "react-icons/io5"; 
import blogImage from '../../assets/Image/image1.webp'
import { Steps } from 'rsuite'
import 'rsuite/Steps/styles/index.css';
import { MdOutlineZoomOutMap } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { eventBus } from '../eventBus';

import OwlCarousel from 'react-owl-carousel'; 



function MainContentn() {
  const navigate = useNavigate()
  const [current, setCurrent] = useState(0);  
  const [img, setImg] = useState(mainContentImage)

  const handleClick = (index) => {
    setCurrent(index);  
  };
    
  const stepsData = [
    { title: 'Step 1', description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ', image: image},
    { title: 'Step 2', description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ', image: image},
    { title: 'Step 3', description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ', image: image},
    { title: 'Step 4', description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ', image: image},
    { title: 'Step 5', description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ', image: image},
  ];
 
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,  
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,  
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };
 
  const sendData = () => {
    eventBus.emit('betslipData', 'Hello from Component A');
  };

  const options = {
    loop: true,
    nav: false,
    dots: true,
    smartSpeed: 1500,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      992: {
        items: 1,
      },
    },
  };

  return (
    <>
      <OwlCarousel className="banner-slider" {...options}>
        <div className="item">
          <img src={img} alt="Banner 1" />
        </div>
        <div className="item">
          <img src={img} alt="Banner 2" />
        </div>
        <div className="item">
          <img src={img} alt="Banner 3" />
        </div>
      </OwlCarousel>

      <div className="button-container mt-3">
        <button className="jumbo-btn jumbo-bg-gradient me-2">Upcoming Matches</button>
        <button className="jumbo-btn me-2">Live Matches</button>
        <button className="jumbo-btn">Finished Matches</button>
      </div>


      <div className="row mb-3">
        <Slider className="slider-custom" {...settings}>  
          {/* First Card */}
          <div className="slider-card col-xl-6 col-lg-12 col-md-12">  
            <div className="dropdown-widget mt-3 me-2">
              <ul>
                <li className="list-heading border-0 carousel-radius">
                  <div className="d-flex justify-content-center align-items-center">
                    <img src={ellipse} alt="circle" style={{ width: '22px', height: '22px' }} />
                    <a className='text-center ps-2'>
                      Eredivisie league
                    </a>
                  </div>
                </li>
                <div className="card-bg-gradient">
                  <div className="d-flex align-items-center justify-content-evenly" style={{ cursor: "pointer" }}  onClick={() => navigate('/live-bet')}>
                    <div className="team-container mt-3" >
                      <div className='teamone-image text-center'>
                        <img src={teamOne} alt="teama" className="teama-image" />
                        <img src={teamOne} alt="teama" className="team-image teamone-opacity" />
                      </div>
                      <p className='text-center mt-2'>IND</p>
                    </div>
                    <div>
                      <button className='btn-timing'>
                        7h 34m
                      </button>
                      <p className='text-center text-small'>Today 7:30 am</p>
                    </div>
                    <div className="team-container mt-3">
                      <div className='teamtwo-image text-center'>
                        <img src={teamTwo} alt="teama" className="teamb-image" />
                        <img src={teamTwo} alt="teama" className="team-image teamtwo-opacity" />
                      </div>
                      <p className='text-center mt-2'>SCO</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-evenly">
                    <button className='btn-betting mb-3' onClick={sendData}>
                      3.5 <br /> <span className='fw-600'>India</span>
                    </button>
                    <button className='btn-betting mb-3'>
                      5.5 <br /> <span className='text-disable fw-600'>Draw</span>
                    </button>
                    <button className='btn-betting mb-3'>
                      6.85 <br /><span className='text-disable fw-600'>Scotland</span>
                    </button>
                  </div>
                </div>
              </ul>
            </div>
          </div>

          {/* Second Card */}
          <div className="slider-card col-xl-6 col-lg-12 col-md-12"> 
            <div className="dropdown-widget mt-3 me-2">
              <ul>
                <li className="list-heading border-0 carousel-radius">
                  <div className="d-flex justify-content-center align-items-center">
                    <img src={ellipse} alt="circle" style={{ width: '22px', height: '22px' }} />
                    <a className='text-center ps-2'>
                      Eredivisie league
                    </a>
                  </div>
                </li>
                <div className="card-bg-gradient">
                  <div className="d-flex align-items-center justify-content-evenly">
                    <div className="team-container mt-3">
                      <div className='teamone-image text-center'>
                        <img src={teamthree} alt="teama" className="teama-image" />
                        <img src={teamthree} alt="teama" className="team-image teamone-opacity" />
                      </div>
                      <p className='text-center mt-2'>AFG</p>
                    </div>
                    <div>
                      <button className='btn-timing'>
                        7h 34m
                      </button>
                      <p className='text-center text-small'>Today 7:30 am</p>
                    </div>
                    <div className="team-container mt-3">
                      <div className='teamtwo-image text-center'>
                        <img src={teamfour} alt="teama" className="teamb-image" />
                        <img src={teamfour} alt="teama" className="team-image teamtwo-opacity" />
                      </div>
                      <p className='text-center mt-2'>AUS</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-evenly">
                    <button className='btn-betting mb-3'>
                      3.5 <br /> <span className='fw-600'>Afghanistan</span>
                    </button>
                    <button className='btn-betting mb-3'>
                      5.5 <br /> <span className='text-disable fw-600'>Draw</span>
                    </button>
                    <button className='btn-betting mb-3'>
                      6.85 <br /><span className='text-disable fw-600'>Australia</span>
                    </button>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </Slider>
      </div>



        <div className='bg-dark-blue mb-3'>
          <h5 className='text-center mb-0'>how to play</h5>
        </div>

        <Steps current={current}>
        {stepsData.map((step, index) => (
          <Steps.Item
            key={index}
            onClick={() => handleClick(index)}
            style={{ cursor: 'pointer' }}
            icon={null}
          >
            {step.title}
          </Steps.Item>
        ))}
      </Steps>

      <div className='jumbo-card mt-3'>
        {stepsData.map((step, index) => (
          current === index && (
            <div key={index} className='d-flex flex-column flex-md-row flex-lg-column flex-xl-row justify-content-start align-items-center'>
              <img 
                src={step.image} 
                className='jumbo-card-img' 
                alt={step.title} 
                width={460} 
                height={350} 
                style={{ borderRadius: '10px', maxWidth: '100%', height: 'auto' }} 
              />
              <div className="d-flex flex-column ms-md-4 mt-3 mt-md-0">
                <h5 className='py-3 text-center text-md-start'>{step.title}</h5>
                <p className='fw-normal text-center text-md-start'>{step.description}</p>
                <p className='fw-normal text-center text-md-start'>{step.description}</p>
              </div>
            </div>
          )
        ))}
      </div>


        <div className='bg-dark-blue my-4 d-flex align-items-center justify-content-between'>
          <h5 className='  mb-0'>Blogs</h5>
          <button className=' jumbo-btn  jumbo-border  mb-0' style={{cursor:'pointer'}} onClick={()=>navigate('/all-blog')}>View All</button>
        </div>

        <div className="row mb-5">
          <div className= " col-xl-4 col-lg-12 col-md-6 col-sm-12 mb-lg-3 mb-md-3 mb-sm-3">
            <div className="blog-card">
              <img src={blogImage} alt="Card Top" className="card-img" />
              <div className="card-content">
                <p className="text-dark">How to download & install SIXER 11</p>
                <div className="card-bottom">
                  <button className="blog-btn">
                    237 <IoEyeOutline className="ms-3 fs-5" />
                  </button>
                  <div className="card-details">
                    <button className="blog-btn" onClick={()=>navigate('/blog-detail')}>
                      Details <MdOutlineZoomOutMap className="ms-3 fs-5" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-lg-12 col-md-6 col-sm-12 mb-lg-3 mb-md-3 mb-sm-3">
            <div className="blog-card">
              <img src={blogImage} alt="Card Top" className="card-img" />
              <div className="card-content">
                <p className="text-dark">How to download & install SIXER 11</p>
                <div className="card-bottom">
                  <button className="blog-btn">
                    237 <IoEyeOutline className="ms-3 fs-5" />
                  </button>
                  <div className="card-details">
                    <button className="blog-btn" onClick={()=>navigate('/blog-detail')}>
                      Details <MdOutlineZoomOutMap className="ms-3 fs-5" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-lg-12 col-md-6 col-sm-12 mb-lg-3 mb-md-3 mb-sm-3">
            <div className="blog-card">
              <img src={blogImage} alt="Card Top" className="card-img" />
              <div className="card-content">
                <p className="text-dark">How to download & install SIXER 11</p>
                <div className="card-bottom">
                  <button className="blog-btn">
                    237 <IoEyeOutline className="ms-3 fs-5" />
                  </button>
                  <div className="card-details">
                    <button className="blog-btn" onClick={()=>navigate('/blog-detail')}>
                      Details <MdOutlineZoomOutMap className="ms-3 fs-5" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

    </>
  )
}

export default MainContentn
