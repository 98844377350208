import React, { useEffect, useState } from 'react'
import betslip from '../../assets/Logo/invoice_2527505 1.png'
import leader from '../../assets/Logo/leader (2) 1.png'
import rectangle from '../../assets/Logo/Rectangle 86.png'
import { MdClose } from 'react-icons/md'
import { eventBus } from '../eventBus';

function RightSide() {
  const [data, setData] = useState('');
  const [details, setDetails] = useState([]);
  useEffect(() => {
    eventBus.on('betslipData', (message) => setData(message));
    eventBus.on('betslipDetail', (message) => setDetails(message));
  }, []);


  const sendData = () => {
    eventBus.emit('isLogin', true);
  };

  return (
    <>
       <div className="main-sitebar">
        <div className="dropdown-widget">
          <ul>
            <li className="list-heading">
              <a>
                <img src={betslip} alt="img"  style={{borderRadius:'0px'}}/>
                <h6 className='mt-1 mb-0'>Bet slip</h6>
              </a>
            </li>
            {
              !data ? 
              <div className='d-flex flex-column align-items-center justify-content-center px-4 betslip-empty' style={{height:'300px'}}>
              <img src={rectangle} alt="rectangle" className='mb-4' />
              <h6>
              Your betslip is empty
              </h6>
              <p className='text-small text-center'>Click the odds to place the bet in your betslip.</p>
            </div> 
              :
              <div className="betslip-card">
                <div className="betslip-header ">
                  <div className="d-flex align-items-center justify-content-between">
                  <p className='text-small mb-0'>Aug | 11.30 AM</p>
                  <p className='text-small mb-0'>IND vs SCO <MdClose className='mb-1 ms-1' /></p>
                  </div>
                </div>
                <div className="betslip-footer mt-0">
                  <div className="d-flex align-items-center justify-content-between">
                  <p className='text-small mb-0'>Who will win the match ? <br />
                    <span className='fw-600'>India</span>
                  </p>
                  <p className='text-small mb-0 fw-600'>4.58</p>
                  </div>
                </div>
                <hr  className='p-0'/>
                <div className="betslip-btn">
                  <div className="d-flex align-items-center justify-content-between">
                    <button className='text-small'>$5</button>
                    <button className='text-small'>$50</button>
                    <button className='text-small jumbo-active-btn'>$500</button>
                    <button className='text-small'>$1000</button>
                  </div>
                </div>
                <div className="betslip mt-3 mb-2">
                  <div className="d-flex align-items-center justify-content-between mx-2">
                  <p className='text-small mb-0'>bet amount <br />$500</p> <span className='pipe'>|</span>
                  <p className='text-small mb-0'>potential payout <br /><span className='float-end'>$2290</span></p>
                  </div>
                </div>
                <button className=' jumbo-bet-btn jumbo-active-btn m-2 my-2' onClick={sendData}>Login</button>
              </div>
            }
            
          </ul>
        </div>

        <div className="dropdown-widget">
          <ul>
            <li className="list-heading">
              <a>
                <img src={leader} style={{borderRadius:'0px'}} alt="img" /> <h6 className='mt-1 mb-0'>Leaderboard</h6>
              </a>
            </li>
            <li>
              <div className="media"> 
                <div className="media-body d-flex  align-items-center justify-content-between">
                  <p className="title mb-0">
                    <a  >Kevin G. Frank</a>
                  </p> 
                  <p className='me-3 mb-0'>$5600</p>
                </div>
              </div>
            </li>
            <li>
              <div className="media"> 
                <div className="media-body d-flex  align-items-center justify-content-between">
                  <p className="title mb-0">
                    <a  >Bruce Godfrey</a>
                  </p> 
                  <p className='me-3 mb-0'>$5380</p>
                </div>
              </div>
            </li>
            <li>
              <div className="media"> 
                <div className="media-body d-flex  align-items-center justify-content-between">
                  <p className="title mb-0">
                    <a  >Shannon R. Chen</a>
                  </p> 
                  <p className='me-3 mb-0'>$4520</p>
                </div>
              </div>
            </li>
            <li>
              <div className="media"> 
                <div className="media-body d-flex  align-items-center justify-content-between">
                  <p className="title mb-0">
                    <a  >Hector E. Vasquez</a>
                  </p> 
                  <p className='me-3 mb-0'>$1298</p>
                </div>
              </div>
            </li>
            <li>
              <div className="media"> 
                <div className="media-body d-flex  align-items-center justify-content-between">
                  <p className="title mb-0">
                    <a  >Freida J. Rainey</a>
                  </p> 
                  <p className='me-3 mb-0'>$300</p>
                </div>
              </div>
            </li>
            <li>
              <div className="media"> 
                <div className="media-body d-flex  align-items-center justify-content-between">
                  <p className="title mb-0">
                    <a  >Donald E. Spencer</a>
                  </p> 
                  <p className='me-3 mb-0'>$245</p>
                </div>
              </div>
            </li>
          </ul>
        </div> 
        
      </div>
    </>
  )
}

export default RightSide
