import { Route, Routes } from 'react-router-dom';
import Main from './component/partial/Main';
import logo from './logo.svg';

function App() {
  return (
    <>
      <Routes>
        <Route path='*' element={<Main/>}/>
      </Routes>
    </>
  );
}

export default App;
