import React from 'react'
import { Route, Routes } from 'react-router-dom'
import LiveBets from '../livebets/LiveBets'

function LiveBetRoute() {
  return (
    <Routes>
        <Route path='/live-bet' element={<LiveBets/>} />
    </Routes>
  )
}

export default LiveBetRoute
