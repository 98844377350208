import React from 'react'
import { IoEyeOutline } from 'react-icons/io5'
import { MdOutlineZoomOutMap } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import blogImage from '../../assets/Image/image1.webp'
import { IoIosArrowBack } from 'react-icons/io'

function AllBlogs() {
    const navigate = useNavigate()
  return (
    <>
        <div className="bg-dark-blue px-3">
            <div className="d-flex align-items-center">
                <div className='d-flex align-items-center justify-content-start'> 
                    <IoIosArrowBack  className='fs-3' onClick={()=>navigate('/')} style={{cursor:'pointer'}}/>
                    <h5 className="mb-0 py-1 ps-2">Blogs</h5>
                </div>
            </div>
        </div>

        <div className="row mt-4">
          {[...Array(6)].map((_, index) => (
            <div className=" col-xl-4 col-lg-12 col-md-6 col-sm-12 mb-3" key={index}>
              <div className="blog-card">
                <img
                  src={blogImage}
                  alt="Card Top"
                  className="card-img img-fluid" // Make the image responsive
                />
                <div className="card-content">
                  <p className="text-dark">How to download & install SIXER 11</p>
                  <div className="card-bottom d-flex justify-content-between align-items-center">
                    <button className="blog-btn">
                      237 <IoEyeOutline className="ms-2 fs-5" />
                    </button>
                    <div className="card-details">
                      <button
                        className="blog-btn"
                        onClick={() => navigate("/blog-detail")}
                      >
                        Details <MdOutlineZoomOutMap className="ms-2 fs-5" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

    </>
  )
}

export default AllBlogs
