import React from 'react'
import { Route, Routes } from 'react-router-dom'
import MainContentn from '../partial/MainContent'

function HomeRoute() {
  return (
    <Routes>
        <Route path='/' element={<MainContentn/>}/>
    </Routes>
  )
}

export default HomeRoute
