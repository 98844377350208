// Chart.js
import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement, Filler } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement, Filler);

const Chart = () => {
  const data = {
    labels: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: ' ',
        data: [12, 9, 85, 40, 2, 40, 85, 35, 15, 27, 60, 70],
        fill: false,  // Enable filling
        borderColor: '#A6CEE3',
        backgroundColor: '#A6CEE3',
        pointBackgroundColor: '#A6CEE3',
        pointBorderColor: '#A6CEE3',
        pointBorderWidth: 2,
        pointRadius: 5,
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            if (context.parsed.y !== null) {
              label += `: ${context.parsed.y}`;
            }
            return label;
          },
        },
      },
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          color: '#A6CEE3',
        },
        grid: {
          borderColor: 'rgba(240, 240, 240, 10%)',
          color: 'rgba(255, 255, 255, 0.2)',
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          color: '#A6CEE3',
          stepSize: 25,
        },
        grid: {
          borderColor: 'rgba(240, 240, 240, 10%)',
          color: 'rgba(255, 255, 255, 0.2)',
        },
        min: 0,
        max: 100,
      },
    },
  };

  return (
    <div className='chart-dashboard'>
      <Line data={data} options={options} />
    </div>
  );
};

export default Chart;
