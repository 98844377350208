import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ellipse from '../../assets/Logo/Ellipse 7.png';
import league from '../../assets/Logo/maps 1.png';

function LeftSidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(null);
  const [expandedDropdown, setExpandedDropdown] = useState(null); // State to handle dropdown expansion

  const leaguesList = [
    { name: "Dashboard", path: "/dashboard" },
    { name: "Profile", path: "/profile" },
    { name: "My Bets", path: "/my-bets" },
    {
      name: "My Logs",
      path: "/my-logs",
      tournaments: [
        { name: "Deposit Log", path: "/my-logs/deposit-log" },
        { name: "Withdraw Log", path: "/my-logs/withdraw-log" },
        { name: "Transaction Log", path: "/my-logs/transaction-log" }
      ]
    },
    {
      name: "Wallet",
      path: "/wallet",
      tournaments: [
        { name: "Deposit", path: "/wallet/deposit" },
        { name: "Withdraw", path: "/wallet/withdraw" }
      ]
    },
    { name: "Change Password", path: "/change-password" },
    { name: "Refer a Friend", path: "/refer-a-friend" },
    { name: "Log Out" }
  ];

  useEffect(() => {
    const currentPath = location.pathname;
    const activeTopLevel = leaguesList.find(item => item.path === currentPath)?.name || null;
    const activeSubLevel = leaguesList
      .flatMap(item => item.tournaments ? [item, ...item.tournaments] : [item])
      .find(item => item.path === currentPath)?.name || null;

    if (activeSubLevel) {
      setActiveItem(activeSubLevel);
      setExpandedDropdown(leaguesList.find(item => item.tournaments && item.tournaments.some(t => t.path === currentPath))?.name || null);
    } else {
      setActiveItem(activeTopLevel);
      setExpandedDropdown(null);
    }
  }, [location.pathname]);

  const handleItemClick = (itemName, path) => {
    if (itemName === "Log Out") {
      handleLogout();
    } else {
      setActiveItem(itemName);
      if (path) {
        navigate(path);
      }
    }
  };

  const handleLogout = () => {
    navigate('/');
  };

  const handleDropdownToggle = (itemName) => {
    setExpandedDropdown(expandedDropdown === itemName ? null : itemName); 
  };

  const renderList = (list) => (
    <ul>
      {list.map((item, index) => (
        <li key={index}>
          <a
            onClick={() => item.tournaments ? handleDropdownToggle(item.name) : handleItemClick(item.name, item.path)}
            className={activeItem === item.name ? 'gradient-text' : ''}
            role="button"
            aria-expanded={expandedDropdown === item.name ? "true" : "false"}
          >
            <img src={ellipse} alt="circle" /> {item.name}
            {item.tournaments && (
              <i className={`fa fa-angle-right ${expandedDropdown === item.name ? 'rotate' : ''}`} />
            )}
          </a>
          {item.tournaments && expandedDropdown === item.name && (
            <div>
              <ul>
                {item.tournaments.map((tournament, tIndex) => (
                  <li key={tIndex}>
                    <a
                      className={activeItem === tournament.name ? 'gradient-text fw-600' : ''}
                      onClick={() => handleItemClick(tournament.name, tournament.path)}
                    >
                      {tournament.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </li>
      ))}
    </ul>
  );

  return (
    <div className="dropdown-widget">
      <ul>
        <li className="list-heading" style={{cursor:'pointer'}}>
          <a>
            <img src={league} alt="league icon" className="me-1" style={{ width: '28px', height: '28px', borderRadius: '0' }} />{" "}
            For You
          </a>
        </li>
        {renderList(leaguesList)}
      </ul>
    </div>
  );
}

export default LeftSidebar;
